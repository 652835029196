import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import CustomHeroComponent from '../../Components/CustomHeroComponent';
import IndividualsInvestImage from '../../Assets/images/IndividualsInvest.png';
import IndividualInvest from '../../Components/Individuals/Invest';
const { Footer, Content } = Layout;

export default function InvestPage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <CustomHeroComponent imageBg={`${IndividualsInvestImage}`} title={"Investment"} />

                <Content>
                    <IndividualInvest />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
