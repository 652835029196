import React, { useEffect, useState } from "react";
import {
  Container,
  HeaderTitle,
  ServicesCardTitle,
  ServicesCardDescription,
  ServicesButton,
} from "./styles.js";
import { Card, Col, Row, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";
import "./styles.css";
import sanityClient from "../../../client";

export default function BlogCardComponent({ mobileScreen }) {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
              title,
              description,
              slug,
              mainImage{
              asset->{
              _id,
              url
              }
          }
          }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  if (!allPostsData)
    return (
      <Container mobileScreen={mobileScreen}>
        <Skeleton avatar paragraph={{ rows: 7 }} />
      </Container>
    );

  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <HeaderTitle mobileScreen={mobileScreen}>Featured Articles</HeaderTitle>
        <Row gutter={[52, 52]}>
          {allPostsData &&
            allPostsData.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  span={8}
                  key={Math.random()}
                >
                  <Card
                    hoverable
                    style={{ width: "100%", height: "100%" }}
                    cover={
                      <img
                        alt=""
                        src={item.mainImage.asset.url}
                        width={"100%"}
                        height={"300px"}
                        style={{ objectFit: "cover" }}
                      />
                    }
                    actions={[
                      <a
                        href={"/blog/" + item.slug.current}
                        key={item.slug.current}
                      >
                        <ServicesButton>Read More</ServicesButton>
                      </a>,
                    ]}
                  >
                    <Meta
                      title={
                        <ServicesCardTitle>{item.title}</ServicesCardTitle>
                      }
                      description={
                        <ServicesCardDescription>
                          {item.description}
                        </ServicesCardDescription>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Container>
    </React.Fragment>
  );
}
