import React from 'react';
import styled from "styled-components";
import { data } from './Timelinedata/data';
import BottomHeader from './BottomHeader';
import { Collapse } from 'antd';
import useMediaQuery from '../useMediaQuery/useMediaQuery';
const { Panel } = Collapse;

export default function AboutOurTimeline() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");

    return (
        <React.Fragment>
            <BottomHeader mobileScreen={mobileScreen} />
            <Container mobileScreen={mobileScreen}>
                <CompanyProfileBody>
                    Discover how our history of organic growth and strategic acquisitions
                    lets us offer clients both local expertise and a global perspective,
                    and uniquely combines modern thinking and nimbleness, with tradition and trust.
                </CompanyProfileBody>
                <CompanyProfileTitle>
                    Timeline
                </CompanyProfileTitle>
                {data.map((item) =>
                    <Collapse
                        defaultActiveKey={['']}
                        expandIconPosition={'right'}
                        key={item.id}
                    >
                        <Panel header={item.title}>
                            <div>{item.desc1}</div>
                            <div>{item.desc2}</div>
                            <div>{item.desc3}</div>
                        </Panel>
                    </Collapse>
                )}
            </Container>
        </React.Fragment>
    )
}

const Container = styled.div`
    padding: ${props => props.mobileScreen ? "40px 100px 60px 100px" : "10px 20px"};
`;

const CompanyProfileTitle = styled.h4`
font-weight: 500;
font-size: 30px;
line-height: 45px;
color: #000000;
text-align: left;
`;
const CompanyProfileBody = styled.p`
font-size: 18px;
line-height: 30px;
    color: #000000;
    text-align: left;
    width: 100%;
`;