
export const SectionData = [
    {
        id: Math.random(),
        description: `
       Abayomi’s foresight approach of anticipating our business needs is refreshing and gives
        me peace of mind. I have found his advice regarding investment opportunities particularly 
        helpful – everything is explained fully, no matter how complex the subject. 
        `,
        fullname: `Jokotola`,
    },
    {
        id: Math.random(),
        description: `
        We have been with AEG now for a few years. Their team has gotten to know us and so 
        the service we receive feels very personal. AEG has helped us to focus on what we 
        want out of life and is helping us realize our financial plans.
        `,
        fullname: `Mr.& Mrs. Obinna`,
    },
    {
        id: Math.random(),
        description: `
        AEG provides an excellent service, be it on a business or on a personal level. 
        I have found the company's advice regarding investment 
        opportunities particularly helpful - everything is explained fully, no matter how complex the subject.
        `,
        fullname: `Chidimma`,
    },
    {
        id: Math.random(),
        description: `
        I am a cautious investor with multiple real estate investments in my portfolio. 
        I have participated in numerous investment opportunities offered by the AEG team 
        at Park101 over the past few years.  The ROI in all cases has been very positive 
        with returns exceeding the initial goals.
        `,
        fullname: `Mr. Smith`,
    },
];
