import React from "react";
import {
  Container,
  HeaderTitle,
  ServicesButton,
  ServicesCardTitle,
  ServicesCardDescription,
} from "./styles.js";
import { Card, Col, Row, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";
import { useQuery } from "react-query";
import defaultImage from "../../Assets/images/busAdvisory-3.png";
import "./styles.css";

export default function NewsCardSection({ mobileScreen }) {
  const { isLoading, error, data, isFetching } = useQuery("repoData", () =>
    fetch(
      "https://newscatcher.p.rapidapi.com/v1/latest_headlines?topic=business&lang=en&media=True",
      {
        method: "GET",
        headers: {
          "x-rapidapi-host": "newscatcher.p.rapidapi.com",
          "x-rapidapi-key": process.env.REACT_APP_NEWS_API_KEY,
        },
      }
    ).then((res) => res.json())
  );

  if (isLoading || isFetching) {
    <Skeleton avatar paragraph={{ rows: 4 }} />;
  }

  if (error) return console.error(error.message);

  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <HeaderTitle mobileScreen={mobileScreen}>Business News</HeaderTitle>
        <Row gutter={[52, 52]}>
          {data?.articles
            ?.sort(() => Math.random() - Math.random())
            .filter((item, idx) => idx < 3)
            .map(({ title, summary, link, media }) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  span={8}
                  key={Math.random()}
                >
                  <a href={link} target="_blank" rel="noreferrer">
                    <Card
                      hoverable
                      style={{ width: "100%", height: "100%" }}
                      cover={
                        <img
                          alt=""
                          src={
                            media === null || media === ""
                              ? defaultImage
                              : media
                          }
                          width={"100%"}
                          height={"300px"}
                          style={{ objectFit: "cover" }}
                        />
                      }
                      actions={[<ServicesButton>Read More</ServicesButton>]}
                    >
                      <Meta
                        title={<ServicesCardTitle>{title}</ServicesCardTitle>}
                        description={
                          <ServicesCardDescription>
                            {`${summary.substring(0, 200)}...`}
                          </ServicesCardDescription>
                        }
                      />
                    </Card>
                  </a>
                </Col>
              );
            })}
        </Row>
      </Container>
    </React.Fragment>
  );
}
