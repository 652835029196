import React from "react";
import BottomHeader from "./BottomHeader";
import DailyRateComponent from "../DailyRate";
import IndividualsHeader from "./Header";
import IndividualHeaderCards from "./Header/card";
import InvestmentCards from "./InvestmentCard";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { dataForex } from "./InvestmentServices/data";
import InvestmentServicesComponent from "./InvestmentServices";

export default function IndividualForex() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `Forex risk management
        For businesses, corporates and institutions
        `,
    description: `Expertise to manage your currency and FX risk
        From simple cross-border trade-related payments to sophisticated hedging solutions, we help you manage all your foreign exchange requirements.
        Our foreign exchange dealers will work together with you to understand your FX risk and develop a tailored strategy for you – helping you stay ahead of the impact of currency volatility on your investment and business.
        `,
  };

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <IndividualsHeader mobileScreen={mobileScreen} data={data} />
      <IndividualHeaderCards mobileScreen={mobileScreen} />
      <DailyRateComponent mobileScreen={mobileScreen} />
      <InvestmentServicesComponent
        mobileScreen={mobileScreen}
        data={dataForex}
      />
      <InvestmentCards mobileScreen={mobileScreen} />
      {/* <TimelineDataComponent mobileScreen={mobileScreen} /> */}
    </React.Fragment>
  );
}
