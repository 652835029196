import { ReactComponent as Investment } from "../../../Assets/icons/Investment.svg";
import { ReactComponent as Save } from "../../../Assets/icons/Save.svg";
import { ReactComponent as Realestate } from "../../../Assets/icons/Real-estate.svg";
import { ReactComponent as Forex } from "../../../Assets/icons/Forex.svg";
import { ReactComponent as Advisory } from "../../../Assets/icons/Advisory.svg";
import { ReactComponent as Equity } from "../../../Assets/icons/Equity.svg";
import { ReactComponent as Portfolio } from "../../../Assets/icons/Portfolio.svg";


export const SectionData = [
  {
    key: Math.random(),
    tab: "You",
    icon1: <Investment />,
    text1: "Invest",
    icon2: <Forex />,
    text2: "Forex",
    icon3: <Save />,
    text3: "Save",
    icon4: <Realestate />,
    text4: "Real Estate",
    link1: "/individuals",
    link2: "/individuals/forex",
    link3: "/individuals/save",
    link4: "/individuals/real-estate",
  },
  {
    key: Math.random(),
    tab: " Your Business",
    icon1: <Investment />,
    text1: "Invest",
    icon2: <Forex />,
    text2: "Forex",
    icon3: <Advisory />,
    text3: "Advisory",
    link1: "/business",
    link2: "/business/forex",
    link3: "/business/advisory",
  },
  {
    key: Math.random(),
    tab: "Corporates & Institutions",
    icon1: <Portfolio />,
    text1: "Portfolio",
    icon2: <Equity />,
    text2: " Equity ",
    icon3: <Forex />,
    text3: "Forex",
    link1: "/corporate",
    link2: "/corporate",
    link3: "/corporate",
  },
];
