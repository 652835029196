
export const BottomData = [
    {
        title: 'Why Us',
        path: '/about-us',
        id: Math.random()
    },
    {
        title: 'Our Business',
        path: '/about-us/business',
        id: Math.random()
    },
    {
        title: 'Our History',
        path: '/about-us/timeline',
        id: Math.random()
    }
];
