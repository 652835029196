import React from 'react';
import BottomHeader from './BottomHeader';
import ServicesCards from './OurServices';
import OurBusinesses from './OurBusinesses';
import QuoteSection from '../QuoteSection'; 
import OurClients from './OurClients';
import BreadcrumbComponent from '../Breadcrumb';
import useMediaQuery from '../useMediaQuery/useMediaQuery';

export default function HomeContentSeactions() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");

    return (
        <React.Fragment>
            <BottomHeader mobileScreen={mobileScreen} />
            <ServicesCards mobileScreen={mobileScreen} />
            <BreadcrumbComponent mobileScreen={mobileScreen} />
            <OurBusinesses mobileScreen={mobileScreen} />
            {/*} <OurTrackRecord mobileScreen={mobileScreen} /> */}
            <QuoteSection mobileScreen={mobileScreen} /> 
            <OurClients mobileScreen={mobileScreen} />
        </React.Fragment>
    )
}
