import styled from "styled-components";

const Container = styled.div`
  padding: ${(props) =>
    props.mobileScreen ? "120px 100px 120px 100px" : "70px 30px"};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.h4`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "32px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  margin-bottom: 50px;
`;
const HeaderTitleSmall = styled.h4`
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: ${(props) => (props.mobileScreen ? "30px" : "25px")};
  line-height: ${(props) => (props.mobileScreen ? "45px" : "40px")};
  color: #0b0d17;
  font-weight: 400;
  color: #0b0d17;
`;

export { Container, HeaderTitle, HeaderTitleSmall };
