import React, { useState } from 'react';
import styled from "styled-components";
import { Row, Col, Space, Spin } from 'antd';
// import { Space, Spin } from 'antd';
import {
    CustomFormGroup, CustomInput,
    CustomInputButton, CustomTextarea
} from "./styles";

export default function FormsComponent() {
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async () => {
         setIsLoading(true)
      };
    
    return (
        <React.Fragment>
            <FormsLayoutWrapper>
                <CustomFormGroup>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                            <DetailsLayoutTitle>
                                Let's talk
                            </DetailsLayoutTitle>
                        </Col>
                    </Row>
                    <form action="https://assetandequitygroup.us6.list-manage.com/subscribe/post?u=9102f4382a620dbebd65c7398&amp;id=2598b05702" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                                <CustomInput
                                    placeholder={"Full Name"}
                                    type="text" name="FNAME"
                                    className="required" id="mce-FNAME"
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                                <CustomInput
                                    placeholder={"Last Name"}
                                    type="text" name="LNAME"
                                    className="required" id="mce-LNAME"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                                <CustomInput
                                    placeholder={"Email"}
                                    type="email"
                                    name="EMAIL" className="required 
                                email" id="mce-EMAIL"
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                                <CustomInput
                                    placeholder={"Phone Number"}
                                    type="number" name="MMERGE3" className="required"
                                    id="mce-MMERGE3"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[0, 0]}>
                            <CustomTextarea
                                placeholder={"How can we Help?"}
                                type="text" name="MESSAGE" className="required"
                                id="mce-MESSAGE"
                            ></CustomTextarea>

                            <CustomInputButton
                                type="submit"
                                value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                                className="button"
                                onClick={handleRegister}
                            >
                                {isLoading ? (
                                    <Space size="middle">
                                        <Spin size="large" />
                                    </Space>
                                ) : (
                                    "Submit"
                                )}

                            </CustomInputButton>
                        </Row>
                    </form>
                </CustomFormGroup>
            </FormsLayoutWrapper>
        </React.Fragment>
    )
}

const FormsLayoutWrapper = styled.div`
width: 100%;
height: auto;
background: #EEEEEE;
border-radius: 10px;
padding: 30px;
`;

const DetailsLayoutTitle = styled.h4`
font-weight: 500;
font-size: 25px;
line-height: 35px;
margin: 10px 0px 30px 0px;
color: #000;
`;