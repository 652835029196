import styled from "styled-components";

const InvestmentCardWrapper = styled.div`
background-color: #FFFFFF;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "50px 100px 50px 100px" : "70px 20px"};
`;
const HeaderTitle = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: ${props => props.mobileScreen ? "40px" : "32px"};
    line-height:  ${props => props.mobileScreen ? "60px" : "40px"};
    color: #0B0D17;
    padding-left: 10px;
`;

const CompanyProfileWrapper = styled.div`
width:"100%";
`;
const CompanyProfileTitle = styled.h4`
font-weight: 600;
font-size: 35px;
line-height: 45px;
color: #0B0D17;
`;
const CompanyProfileBody = styled.p`
font-size: 18px;
line-height: 30px;
    color: #0B0D17;
    width: '100%';
    text-align: left;
`;

const InvestmentCardBox = styled.div`
    width: 100%;
    height: auto;
    background: #7C7C7C;
    box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 30px;
    padding: 60px 30px;
`;

const InvestmentCardTitle = styled.h4`
font-size: 30px;
line-height: 40px;
 color: #FFFFFF;
 text-align: center;
`;

const InvestmentCardImage = styled.img`
width: 70px;
height: 70px;
object-fit: contain;
margin: 0px 0px 40px 0px;
top: 50%;
    left: 50%;
    transform: translate(130px, 10px);s
`;


export {
    InvestmentCardWrapper, Container,
    HeaderTitle, InvestmentCardBox, InvestmentCardTitle,
    InvestmentCardImage, CompanyProfileWrapper,
    CompanyProfileTitle, CompanyProfileBody
};