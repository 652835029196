export const data = [
  {
    id: Math.random(),
    title: `Pent Houses`,
    description: `
        Perched on top of this exclusive 
        4-storey water-front luxury apartment 
        is 3-bedroom, 2 bedroom and 4-bedroom 
        penthouses with an enchanting view of the 
        Atlantic. Experience the very best in 
        contemporary beachfront living at these 
        duplex penthouses`,
    Color: "#F3CA40",
    saving: `Features`,
    payment: `Payment Plan`,
    asset: `Apartment Plan`,
  },
  {
    id: Math.random(),
    title: `Duplexes`,
    description: `Outstanding and remarkable are just some
    of the qualities of our duplexes. 
    The 4 bedroom terrace duplexes with bq
    are plush, modern, and state-of-the-art 
    finishes that abound with all the comforts 
    that one might expect of a five-star hotel.`,
    Color: "#BCE27F",
    width: `211px`,
    saving: `Features`,
    payment: `Payment Plan`,
    asset: `Apartment Plan`,
  },
  {
    id: Math.random(),
    title: `Apartments`,
    description: `Enjoy panoramic views of nature in Our Two 
    Bedroom, One Bedroom Studio residences 
    are meticulously designed, offering a 
    harmonious blend of kitchen surfaces and 
    contemporary finishes.`,
    Color: "#F08A4B",
    saving: `Features`,
    payment: `Payment Plan`,
    asset: `Apartment Plan`,
  },
];
