import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import AEGLOGO from "../../Assets/images/Logo.svg";

export default function HeaderComponent() {
  const { Header } = Layout;

  return (
    <React.Fragment>
      <Header>
        <PageHeader>
          <a href="/">
            <img
              width={"100%"}
              height={"100%"}
              src={AEGLOGO}
              loading="lazy"
              alt=""
            />
          </a>
          <PageHeaderWrapper>
            {/* <PageHeaderButton width={'150px'} style={{ marginRight: '10px' }}>
                            Become a Client
                        </PageHeaderButton>
                        <PageHeaderButton bg={'#ffffff'} color={'#0B0D17'}>
                            Login
                        </PageHeaderButton> */}
          </PageHeaderWrapper>
        </PageHeader>
      </Header>
    </React.Fragment>
  );
}

const PageHeader = styled.div`
  background-color: #0b0d17;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
// const PageHeaderButton = styled.div`
//   width: ${(props) => (props.width ? props.width : "77px")};
//   height: 40px;
//   background: ${(props) => (props.bg ? props.bg : "#F3CA40")};
//   border-radius: 5px;
//   font-size: 16px;
//   font-weight: 500;
//   line-height: 33px;
//   text-align: center;
//   color: ${(props) => (props.color ? props.color : "#fff")};
// `;
