import React from "react";
import { SectionData } from "./data";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function ClientBox({ mobileScreen }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <Carousel
        responsive={responsive}
        showDots={true}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      >
        {SectionData.map((item) => {
          return (
            <OurClientsWrapper key={item.id}>
              <OurClientBox mobileScreen={mobileScreen}>
                <OurClientBoxDescription mobileScreen={mobileScreen}>
                  {item.description}
                </OurClientBoxDescription>

                <OurClientsOwnName>{item.fullname}</OurClientsOwnName>
              </OurClientBox>
            </OurClientsWrapper>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
}

const OurClientsWrapper = styled.div`
  margin-left: 40px;
`;
const OurClientBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
  height: ${(props) => (props.mobileScreen ? "400px" : "100%")};
  width: 100%;
  border-radius: 20px;
  padding: 40px;

  @media screen and (min-height: 800px) {
    height: 100%;
  }
`;

const OurClientsOwnName = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
  color: #18191f;
`;

const OurClientBoxDescription = styled.p`
  font-size: ${(props) => (props.mobileScreen ? "18px" : "16px")};
  line-height: 30px;
  color: #0b0d17;
`;
