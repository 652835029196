import Image_1 from "../../../Assets/images/busInvestImage-1.png";
import Image_2 from "../../../Assets/images/busInvestImage-2.png";
import Image_3 from "../../../Assets/images/busInvestImage-3.png";
import Image_4 from "../../../Assets/images/busInvestImage-4.png";
import Image_5 from "../../../Assets/images/busInvestImage-5.png";
import Image_6 from "../../../Assets/images/busInvestImage-6.png";

import Forex_1 from "../../../Assets/images/IndividForex-1.png";
import Forex_2 from "../../../Assets/images/IndividForex-2.png";
import Forex_3 from "../../../Assets/images/IndividForex-3.png";

export const dataInvest = [
  {
    id: Math.random(),
    title: `Asset Management`,
    description: `
        Owned by clients managed for clients. We are a different kind of asset manager.
        Our asset management strategies focus on the preserving and accumulating wealth.
        Our team of world-class investment management professionals take a more balanced, multi-strategy approach. We have developed a series of investment models designed to meet the wide variety of your financial goals and risk parameters.
        `,
    image: Image_1,
  },
  {
    id: Math.random(),
    title: `Private Equity`,
    description: `Our private equity services cover the whole lifecycle of the fund to create markets that open up opportunities for all.
    We focus on implementing the highest quality service delivery and wrapping that framework around the best hands in the industry.
    At Asset and Equity Group, we provide scalable services leveraging on our team of private equity experts to deliver financial support and our results are powerful. 
        `,
    image: Image_2,
  },
  {
    id: Math.random(),
    title: `Fixed Income`,
    description: `
    Generate a steady source of income and enjoy attractive returns. No matter your financial goals, enjoy diversification from stock market risk, capital preservation, income generation, total return among other benefits.
    We help you allocate portions of your portfolio to fixed income investment to help you offset losses when the stock market swings. Fixed income typically carries less risk, these assets can be a good choice for you to recoup losses especially within a short time.     
    `,
    image: Image_3,
  },
  {
    id: Math.random(),
    title: `Wealth Management`,
    description: `
      Our wealth management reputation is built on successfully working with a wide range of clients. From one-stop multi asset solutions to funds that focus on specific areas of specific markets, we’ve worked with clients to create investment solutions that help them preserve their goals.
We work closely with clients to understand their objectives and requirements, and then work to deliver the performance and service they deserve. 
    `,
    image: Image_4,
  },
  {
    id: Math.random(),
    title: `Mutual Funds`,
    description: `
      We use multi-advisor approach to help reduce volatility and deliver a more predictable pattern of returns. All of our fund managers—in-house and external—are held to the rigorous standards of the manager selection and oversight process that we've refined over years. 
Our conviction that top talent, low costs, and patience drives successful mutual funds coupled with our rigorous manager selection and oversight process, has produced impressive long-term results.

    `,
    image: Image_5,
  },
  {
    id: Math.random(),
    title: `Financial Advisory`,
    description: `
      Our team of advisors are committed to helping clients develop customized financial plans to meet their needs.
We work to provide lower-risk, regulatory advisory, investment management strategies, helping our esteemed clients grow their businesses.
And, our years of experience allow us to provide you with the tools and knowledge needed to make the most informed decisions for securing your financial future.
    `,
    image: Image_6,
  },
];

export const dataForex = [
  {
    id: Math.random(),
    title: `Cash Services`,
    description: `
    Do you need cash? Buy and sell foreign exchange directly with us at the click of a button. We are always ready to receive your orders by phone or mail or our social media platforms. We assure you of competitive rates in a safe business environment.
          `,
    image: Forex_1,
  },
  {
    id: Math.random(),
    title: `Payment Services`,
    description: `Do you want to pay for importation? We deliver secure service to drive customer satisfaction. Automatic, fast and yet personalized service. We will connect you with an account manager who knows your business inside out.
          `,
    image: Forex_2,
  },
  {
    id: Math.random(),
    title: `Full Payment Processing`,
    description: `
Leave your foregin exchange needs to us and spend more time building your business. Get the best exchange rates with our solution built for business and save on all transactions.
      `,
    image: Forex_3,
  },
];
