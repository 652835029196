
export const BottomData = [
    {
        title: 'Invest',
        path: '/individuals',
        id: Math.random()
    },
    {
        title: 'Forex',
        path: '/individuals/forex',
        id: Math.random()
    },
    {
        title: 'Savings',
        path: '/individuals/save',
        id: Math.random()
    },
    {
        title: 'Real Estate',
        path: '/individuals/real-estate',
        id: Math.random()
    },
];
