import Image_1 from "../../../../Assets/images/directors-1.png";
import Image_2 from "../../../../Assets/images/directors-2.jpg";
import Image_3 from "../../../../Assets/images/directors-3.jpg";
import Image_4 from "../../../../Assets/images/directors-4.png";

export const data = [
    {
        id: Math.random(),
        title: 'Abiola Adediran',
        description: `Chairwoman`,
        image: Image_2
    },
   {
        id: Math.random(),
        title: 'Abayomi Michael',
        description: `Group Managing Director`,
        image: Image_1
    }, 
    {
        id: Math.random(),
        title: 'Adedeji Awolola',
        description: `Independent Non-Executive director`,
        image: Image_4
    },
    {
        id: Math.random(),
        title: 'Femi Taiwo',
        description: `Independent Non-Executive director`,
        image: Image_3,
        bgCover: 'cover'
    },
];
