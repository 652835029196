import { Col, Row } from 'antd';
import React from 'react';
import styled from "styled-components";

export default function GetInTouchSection({ mobileScreen }) {
    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                        <HeaderTitle>
                            All-in-one Platform For Business
                            <HeaderDescription>
                            A digital investment platform accessed through Balloon Africa, provides clients access to invest in selected investments aligned to their investment objectives. Whether you are a seasoned or a novice investor, Balloon Africa gives you immediate access to local and international investment opportunities. You have the freedom to invest, buy or sell as you deem fit, in a secure, digital platform.
                            </HeaderDescription>
                        </HeaderTitle>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        <a href="/contact-us">
                            <CustomButton mobileScreen={mobileScreen}>
                                Get In Touch
                            </CustomButton>
                        </a>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

const Container = styled.div`
    padding: ${props => props.mobileScreen ? "80px 100px" : "50px 20px"};
    background-color: #577590;
`;
const HeaderTitle = styled.h4`
font-weight: 500;
font-size: 30px;
line-height: 45px;
color: #FFFFFF;
`;
const HeaderDescription = styled.p`
padding-top: 10px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
width: ${"100%"};
max-width: 700px;
text-align: left;
`;
const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    background: #F3CA40;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: ${props => props.mobileScreen ? "50px" : "0px"};
`;