import React from "react";
import { Col, Row } from "antd";
import { data } from "./data";
import { HeaderTitleSmall } from "../styles.js";
import "./style.css";

export default function ManagementTeam({ mobileScreen }) {
  return (
    <React.Fragment>
      <HeaderTitleSmall
        mobileScreen={mobileScreen}
        style={{ marginTop: "40px" }}
      >
        Management Team
      </HeaderTitleSmall>
      <Row gutter={[24, 24]}>
        {data.map((item) => {
          return (
            <Col xs={24} sm={24} md={12} lg={12} xl={6} span={6} key={item.id}>
              <div className="card">
                {item.bgCover === "cover" ? (
                  <img src={item.image} alt="" style={{ objectFit: "cover" }} />
                ) : (
                  <img
                    src={item.image}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                )}
                <div className="details">
                  <div className="name">
                    <h3>{item.title}</h3>
                    <span>{item.description}</span>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
}
