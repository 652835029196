import React from 'react';
import { Container, HeaderTitle } from './styles.js';
import BoardOfDirectors from './BoardOfDirectors';
import ManagementTeam from './ManagementTeam/index.js';

export default function OurLeadershipSection({ mobileScreen }) {
    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <HeaderTitle mobileScreen={mobileScreen}>
                    Our Leadership
                </HeaderTitle>

                <BoardOfDirectors mobileScreen={mobileScreen} />
                <ManagementTeam mobileScreen={mobileScreen} />
            </Container>
        </React.Fragment>
    );
}

