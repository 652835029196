import React from 'react';
import { Carousel } from 'antd';
import FileHoreComponent from './file';
import ParkHoreComponent from './park101';
import BallonHoreComponent from './ballon';
import useMediaQuery from '../useMediaQuery/useMediaQuery';

export default function HeroComponent() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");


    return (
        <React.Fragment>
            
            <Carousel autoplay arrows >
                <div>
                    <FileHoreComponent mobileScreen={mobileScreen} />
                </div>
                <div>
                    <ParkHoreComponent mobileScreen={mobileScreen} />
                </div>
                <div>
                    <BallonHoreComponent mobileScreen={mobileScreen} />
                </div>
            </Carousel>
        </React.Fragment>
    )
}
