import React from "react";
import { Col, Row } from "antd";
import { VisionData } from "./data";
import {
  OurVisionCardWrapper,
  Container,
  HeaderTitle,
  OurVisionCardBox,
  OurVisionCardTitle,
  OurVisionCardDescription,
} from "./styles.js";
import { InvestmentCardImage } from "../../Individuals/InvestmentCard/styles";

export default function OurVisionCards({ mobileScreen }) {
  return (
    <React.Fragment>
      <OurVisionCardWrapper>
        <Container mobileScreen={mobileScreen}>
          <HeaderTitle mobileScreen={mobileScreen}>Our Values</HeaderTitle>
          <Row gutter={[24, 24]}>
            {VisionData.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={6}
                  span={6}
                  key={item.id}
                >
                  <OurVisionCardBox
                    borderColor={item.Color}
                    mobileScreen={mobileScreen}
                  >
                    <InvestmentCardImage src={item.image} alt="" />
                    <OurVisionCardTitle size={item.width}>
                      {item.title}
                    </OurVisionCardTitle>
                    <OurVisionCardDescription>
                      {item.description}
                    </OurVisionCardDescription>
                  </OurVisionCardBox>
                </Col>
              );
            })}
          </Row>
        </Container>
      </OurVisionCardWrapper>
    </React.Fragment>
  );
}
