import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import CustomHeroComponent from '../../Components/CustomHeroComponent';
import IndividualsforexImage from '../../Assets/images/Individualsforex.png';
import IndividualForex from '../../Components/Individuals/Forex';
const { Footer, Content } = Layout;

export default function ForexPage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <CustomHeroComponent imageBg={`${IndividualsforexImage}`} title={"Foreign Exchange"} />

                <Content>
                    <IndividualForex />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
