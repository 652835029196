import styled from "styled-components";

const OurVisionCardWrapper = styled.div`
  background-color: #ffffff;
`;
const Container = styled.div`
  padding: ${(props) =>
    props.mobileScreen ? "10px 100px 60px 100px" : "10px 30px"};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "32px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  padding-left: 10px;
`;

const OurVisionCardBox = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 60px;
  padding: 60px 20px 10px 20px;
`;

const OurVisionCardTitle = styled.h4`
  font-size: 33px;
  line-height: 45px;
  color: #f2a541;
  width: 100%;
  @media screen and (min-height: 800px) {
    font-size: ${(props) => (props.mobileScreen ? "40px" : "35px")};
  }
`;

const OurVisionCardDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #0b0d17;
`;

export {
  OurVisionCardWrapper,
  Container,
  HeaderTitle,
  OurVisionCardBox,
  OurVisionCardTitle,
  OurVisionCardDescription,
};
