import Image_1 from '../../../Assets/images/forex_1.png';
import Image_2 from '../../../Assets/images/forex_2.png';
import Image_3 from '../../../Assets/images/forex_3.png';

export const Investment = [
    {
        id: Math.random(),
        title: `We aspire to nothing less than excellence, consistently striving for exceptional performance.`,
        image: Image_1
    },
    {
        id: Math.random(),
        title: `We prioritize collaboration and value diversity, creating a culture that fosters inclusiveness, teamwork`,
        image: Image_2
    },
    {
        id: Math.random(),
        title: `Our reputation for integrity is one of our most important assets. `,
        image: Image_3
    },

];
