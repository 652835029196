import React from "react";
import { ServicesCardWrapper, Container, HeaderTitle } from "./styles.js";
import ClientBox from "./card.js";

export default function OurClients({ mobileScreen }) {
  return (
    <React.Fragment>
      <ServicesCardWrapper>
        <Container mobileScreen={mobileScreen}>
          <HeaderTitle mobileScreen={mobileScreen}>
            Our Clients Speak
          </HeaderTitle>
          <ClientBox mobileScreen={mobileScreen} />
        </Container>
      </ServicesCardWrapper>
    </React.Fragment>
  );
}
