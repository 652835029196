
const data = [
    {
        id: Math.random(),
        title: `2007`,
        desc1: `** Olive BDC was founded
        `,
        desc2: `** CDCSL was founded 
        `,
    },
    {
        id: Math.random(),
        title: `2016`,
        desc1: `** CDCSL set to deploy private equity funds for real estate 
        `,
    },
    {
        id: Math.random(),
        title: `2017`,
        desc1: `** CDCSL was licensed by Securities and Exchange Commission
        `,
    },
    {
        id: Math.random(),
        title: `2021`,
        desc1: `** Asset and Equity Limited acquired 53,000sqm waterfront property in Ibeju-Lekki and Park 101 commenced.`,
        desc2: `** Asset and Equity Group acquires CDCSL.`,
        desc3: `** Asset and Equity Group wins Diversified financial services company of the year at Business Day BAFI Awards.`,
    },
    {
        id: Math.random(),
        title: `2022`,
        desc1: `** Silicon Edge launches File.`,
        desc2: `** CDCSL launches its privately managed money managed fund and private real estate fund.`,
    },
]

export { data };