import React from "react";
import BottomHeader from "./BottomHeader";
import DailyRateComponent from "../DailyRate";
import BusinessHeader from "./Header";
import InvestmentCards from "./InvestmentCard";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { Divider } from "antd";
import InvestmentServicesComponent from "./InvestmentServices";
import { dataForex } from "./InvestmentServices/data";

export default function ForexComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `Expertise in managing your currency foreign exchange risk`,
    description: `We assist you in managing all of your foreign exchange requirements, from basic cross-border trade-related payments to complicated hedging solutions. 
Our foreign exchange dealers will work with you to assess your foreign exchange risk and build a 
customized strategy for you, allowing you to stay ahead of the impact of currency volatility on your business.`,
  };

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <BusinessHeader mobileScreen={mobileScreen} data={data} />
      <InvestmentCards mobileScreen={mobileScreen} bgColor={"#fff"} />
      <DailyRateComponent mobileScreen={mobileScreen} />
      <InvestmentServicesComponent
        mobileScreen={mobileScreen}
        data={dataForex}
      />
      <Divider style={{ backgroundColor: "#F3CA40" }} />
      {/* <TimelineDataComponent mobileScreen={mobileScreen} /> */}
    </React.Fragment>
  );
}
