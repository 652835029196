import styled from "styled-components";

const InvestmentCardWrapper = styled.div`
  background-color: #f0f2f5;
`;
const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "30px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  padding-left: 10px;
`;

const InvestmentCardBox = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
  padding: 60px 30px 20px 30px;
`;

const InvestmentCardTitle = styled.h4`
  font-size: ${(props) => (props.mobileScreen ? "29px" : "24px")};
  line-height: 40px;
  color: #0b0d17;
  text-align: center;
`;

const InvestmentCardDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #0b0d17;
  text-align: center;
`;

const InvestmentCardImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin: ${(props) =>
    props.mobileScreen ? "0px 0px 40px 140px" : "0px 0px 40px 90px"};
`;

export {
  InvestmentCardWrapper,
  Container,
  HeaderTitle,
  InvestmentCardBox,
  InvestmentCardTitle,
  InvestmentCardDescription,
  InvestmentCardImage,
};
