// import Image_1 from '../../../Assets/images/homeBusiness1.png';
import Image_2 from '../../../Assets/images/homeBusiness2.png';
import Image_3 from '../../../Assets/images/park.jpg';
import Image_4 from '../../../Assets/images/homeBusiness4.png';
import Image_5 from '../../../Assets/images/business-1.png';
import Image_6 from '../../../Assets/images/olive.png';

export const SectionData = [
    {
        key: Math.random(),
        tab: 'CDCSL',
        image: Image_2,
        description: `
        CDCSL is a fully-fledged multi-asset investment management company, with expertise in 
        portfolio management strategies across different market jurisdictions. 
        CDCSL is licensed and registered by The Securities and Exchange Commission, Nigeria.
        With over 55 years of combined professional experience in 
        creating wealth management solutions, we have built portfolios through our investment 
        strategies that have consistently outperformed the market.
        `,
        link: 'https://www.convergedynamicscapital.com/',
        services_1: 'Asset Management',
        services_2: 'Investment Banking',
        services_3: 'Portfolio Management',
        services_4: 'Advisory Services',
    },
    {
        key: Math.random(),
        tab: 'PARK 101',
        image: Image_3,
        description: `
        PARK 101 is a luxury real estate company with its flagship product located in Ibeju-Lekki area of 
        Lagos state. Located on the coast of the Atlantic in the sought-after town of Ibeju-Lekki, 
        Park 101 offers smart investors the rare privilege of living on the water’s edge in Lagos. 
        Kissed by sun and ocean with picturesque views, the development offers fully owned luxury 
        penthouses, duplexes and high-end apartments overlooking nature.
        `,
        link: 'http://park101.com.ng/',
        services_1: "",
        margin: '5rem'
    },
    {
        key: Math.random(),
        tab: 'Olive',
        image: Image_6,
        description: `
        Olive Bureau De Change is a subsidiary of Asset and Equity Group Ltd. We are a Foreign Exchange Company headquartered in Lagos, 
        Nigeria and licensed by Central Bank of Nigeria to carry on Bureau De Change business in Nigeria.
        Our business focus is to provide foreign exchange services at competitive rates 
        to all our stakeholders using experts in the industry. Businesses and individuals place orders and broker foreign currency transactions more conveniently through us. 
        `,
        link: 'https://www.olivebdc.com/',
        services_1: 'Foreign Exchange Cash Services',
        services_2: 'Payment Services',
        services_3: 'Full Payments processing',
        services_4: '',
    },
    {
        key: Math.random(),
        tab: 'Silicon Edge',
        image: Image_5,
        description: `
            At Silicon Edge, we translate your ideas into reality using the latest technologies and 
            software development approaches. Our core development services is to deliver next-generation, 
            futuristic tech solutions. We are your one-stop solution for end-to-end mobile app development. 
            We help you adapt and evolve in the face of disruptive innovative technologies, dynamic global 
            markets and rapidly changing regulatory environments. 
        `,
        link: 'https://www.siliconedgeltd.com/',
        services_1: 'Website Development',
        services_2: 'Software Development',
        services_3: 'UI/UX',
        services_4: 'SAAS',
    },
    {
        key: Math.random(),
        tab: 'FLYI',
        image: Image_4,
        description: `
       Building A Better Nigeria Through A Financially Educated Citizenry
We believe that young adults need to gain knowledge on money management and 
the profound implications of making financial decisions. Our aim is to shape the 
minds of these Nigerians and ensure they become financially responsible adults.
        `,
        link: '#',
        services_1: "",
        margin: '5rem'
    },
];
