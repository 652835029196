import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";

export default function CompanyProfile({
  mobileScreen,
  Image,
  bgColor,
  title,
  description,
  height,
  link,
  text,
}) {
  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <Row gutter={[0, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileWrapper mobileScreen={mobileScreen} bg={bgColor}>
              <CompanyProfileTitle>{title}</CompanyProfileTitle>
              <CompanyProfileBody mobileScreen={mobileScreen}>
                {description}
              </CompanyProfileBody>
              {text === "" ? (
                ""
              ) : (
                <CompanyProfileLink>
                  <a href={link} target="_blank" rel="noreferrer">
                    {text}
                  </a>
                </CompanyProfileLink>
              )}
            </CompanyProfileWrapper>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileImage
              src={Image}
              alt=""
              mobileScreen={mobileScreen}
              height={height}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  padding: ${(props) =>
    props.mobileScreen ? "90px 100px 0px 100px" : "10px 20px"};
`;
const CompanyProfileWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.mobileScreen ? "650px" : "100%")};
  background: ${(props) => props.bg};
  padding: ${(props) =>
    props.mobileScreen ? "70px 50px 50px 50px" : "50px 20px 50px 20px"};
`;
const CompanyProfileImage = styled.img`
  width: 100%;
  height: ${(props) => (props.mobileScreen ? props.height : "100%")};
  object-fit: cover;
`;
const CompanyProfileTitle = styled.h4`
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
  color: #0b0d17;
`;
const CompanyProfileBody = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #0b0d17;
  width: 100%;
  text-align: left;
  width: 100%;
`;
const CompanyProfileLink = styled.span`
  font-size: 18px;
  line-height: 30px;
  border-bottom: 1px solid #4c4c4e;
  & a {
    color: #0b0d17;
  }
`;
