import React from 'react';
import { MMMWrapper, Container, HeaderTitle } from './styles';

export default function MMMComponent({ mobileScreen }) {
    return (
        <React.Fragment>
            <MMMWrapper>
                <Container mobileScreen={mobileScreen}>
                    <HeaderTitle>
                        Monday Money Moves with SpringBoss
                    </HeaderTitle>

                    <iframe width="100%" height="550"
                        src="https://www.youtube.com/embed/rSksSS8BrlI"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; 
                        encrypted-media; gyroscope; picture-in-picture"
                        style={{ borderRadius: '20px' }}
                        allowFullScreen></iframe>
                </Container>
            </MMMWrapper>
        </React.Fragment>
    );
}
