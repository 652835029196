import { Avatar, Col, Row } from "antd";
import React from "react";
import {
  QuoteSectionWrapper,
  Container,
  QuoteOwnSection,
  QuoteOwnWrapper,
  QuoteOwnName,
  QuoteOwnTitle,
  QuoteWrapper,
  QuoteImage,
  QuoteWorld,
} from "./styles.js";
import Quote from "../../Assets/images/quote.png";
import MdImage from "../../Assets/images/MD.png";
import useMediaQuery from "../useMediaQuery/useMediaQuery.js";

export default function QuoteSection() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  return (
    <React.Fragment>
      <QuoteSectionWrapper>
        <Container mobileScreen={mobileScreen}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
              <QuoteOwnSection>
                <Avatar
                  size={{ xs: 60, sm: 80, md: 90, lg: 90, xl: 100, xxl: 100 }}
                  src={MdImage}
                />
                <QuoteOwnWrapper>
                  <QuoteOwnName>Abayomi Michael Osho</QuoteOwnName>
                  <QuoteOwnTitle>GMD/GCEO Asset and Equity Group</QuoteOwnTitle>
                </QuoteOwnWrapper>
              </QuoteOwnSection>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} span={16}>
              <QuoteWrapper>
                <QuoteImage src={Quote} alt="" />
                <QuoteWorld mobileScreen={mobileScreen}>
                  If you ever care to know what you are worth, check your
                  capacity…We must be in the habit of moving to be great.
                </QuoteWorld>
              </QuoteWrapper>
            </Col>
          </Row>
        </Container>
      </QuoteSectionWrapper>
    </React.Fragment>
  );
}
