import React from 'react';
import { BottomData } from './data';
import { useLocation } from 'react-router-dom';
import { DesktopItem } from '../../Navbar/styles';
import { Container } from './styles';
import { Col, Row } from 'antd';

export default function BottomHeader({ mobileScreen }) {
    // Active Navbar Link Menu
    const { pathname } = useLocation();
    const ActivePage = pathname.replace("/", "");
    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <Row gutter={[24, 16]}>
                    {BottomData.map((item) => {
                        return (
                            <Col key={item.id}>
                                <a href={item.path}>
                                    <DesktopItem active={`/${ActivePage}`} path={item.path}>
                                        {item.title}
                                    </DesktopItem>
                                </a>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </React.Fragment>
    );
}
