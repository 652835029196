import styled from "styled-components";

const Container = styled.div`
    height: ${props => props.mobileScreen ? "100%" : "100%"};
    line-height: 100%;
    padding: ${props => props.mobileScreen ? "80px 70px" : "30px 20px 20px 20px"};
    background-color: #f5f5f5;
`;

const HeroHeader = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 100%;
    margin-top: 2rem;
`;
const HeroHeaderTitle = styled.h3`
    font-weight: 600;
    font-size: ${props => props.mobileScreen ? "92px" : "60px"};
    line-height: ${props => props.mobileScreen ? "100px" : "70px"};
    color: #18191F;
`;
const HeroHeaderDescription = styled.p`
    font-size: 20px;
    line-height: 32px;
    color: #4C4C4E;
    text-align: left;
    margin-bottom: 30px;

   @media screen and (min-height: 800px) {
          padding-right: ${props => props.mobileScreen ? "200px" : "20px"};
    }
`;

const HeroHeaderImage = styled.img`
        width: 100%;
        height: ${props => props.mobileScreen ? "480px" : "300px"};
        object-fit: contain;
`;

const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    width: 100%;
    height: auto;
    background: #000000;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 15px 25px;
`;
export {
    Container, HeroHeader, HeroHeaderTitle,
    HeroHeaderDescription, HeroHeaderImage,
    CustomButton,
};