import styled from "styled-components";

const DailyRateWrapper = styled.div`
background-color: #EEEEEE;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "60px 100px 60px 100px" : "50px 30px"};
`;
const HeaderTitle = styled.div`
font-weight: 500;
font-size: 30px;
line-height: 45px;
color: #0B0D17;
display: flex;
justify-content: ${props => props.mobileScreen ? "initial" : "center"};
text-transform: capitalize;
`;

const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    background: #577590;
    border-radius: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 15px 25px;
`;

export { DailyRateWrapper, Container, HeaderTitle, CustomButton };