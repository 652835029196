import { Col, Row } from 'antd';
import React from 'react';
import { DailyRateWrapper, Container, HeaderTitle, CustomButton } from './styles';

export default function DailyRateComponent({ mobileScreen }) {
    return (
        <React.Fragment>
            <DailyRateWrapper>
                <Container mobileScreen={mobileScreen}>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} span={16}>
                            <HeaderTitle mobileScreen={mobileScreen}>
                                Daily rates
                            </HeaderTitle>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8} style={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}>
                            <a href="https://www.cbn.gov.ng/rates/exchratebycurrency.asp" target="_blank" rel="noreferrer">
                                <CustomButton>
                                    View currency rates
                                </CustomButton>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </DailyRateWrapper>
        </React.Fragment>
    );
}
