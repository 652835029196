import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  line-height: 100%;
  text-align: center;
  padding: ${(props) =>
    props.mobileScreen ? "0px 49px" : "0px 20px 20px 0px"};
`;

// Mobile Styles
const NavMenuItems = styled.ul`
  width: 100%;
`;
const NavMapLink = styled.li`
  list-style: none;
  height: 80px;
  background: ${(props) => (props.active === props.path ? "#FFFFFF" : "")};
  border-radius: 5px 0px 0px 5px;

  & :hover {
    background: #ffffff85;
    border-radius: 5px 0px 0px 5px;
  }

  & a {
    text-decoration: none;
    color: ${(props) => (props.active === props.path ? "#F9A826" : "#181717")};
    font-size: 22px;
    font-weight: ${(props) => (props.active === props.path ? "600" : "400")};
    border-bottom: ${(props) =>
      props.active === props.path ? "1px solid #0B0D17" : ""};
    padding-bottom: ${(props) => (props.active === props.path ? "10px" : "")};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;

    & :hover {
      background: none;
    }
  }
`;

const NavbarMobile = styled.div`
  background-color: #0b0d17;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2rem 0px 2rem;
`;
const NavbarMobileToggle = styled.li`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
`;
const MobileNavbarMenu = styled.nav`
  background-color: #fafbfc;
  width: 270px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebar ? "0" : "-100%")};
  transition: ${(props) => (props.sidebar ? "350ms" : "850ms")};
  z-index: 45;
`;
// End of Mobile styles

// Desktop styles
const DesktopItem = styled.span`
  font-size: 22px;
  line-height: 20px;
  font-weight: ${(props) => (props.active === props.path ? "600" : "400")};
  border-bottom: ${(props) =>
    props.active === props.path ? "1px solid #0B0D17" : ""};
  padding-bottom: ${(props) => (props.active === props.path ? "10px" : "")};
  color: #0b0d17;
`;

export {
  NavMenuItems,
  NavMapLink,
  NavbarMobile,
  NavbarMobileToggle,
  MobileNavbarMenu,
  DesktopItem,
  Container,
};
