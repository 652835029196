import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import OurVisionCards from "./card";

export default function OurVisionSection({ mobileScreen }) {
  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <Row gutter={[48, 12]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileWrapper mobileScreen={mobileScreen}>
              <CompanyProfileTitle>Our Mission</CompanyProfileTitle>
              <CompanyProfileBody mobileScreen={mobileScreen}>
                We are on a mission to add value as a diversified financial
                services group to help our clients reach their financial goals.
                We achieve this through our investment strategies and
                principles, adhering to our values, and offering employees a
                challenging and rewarding place to build a career.
              </CompanyProfileBody>
            </CompanyProfileWrapper>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileWrapper mobileScreen={mobileScreen}>
              <CompanyProfileTitle>Our Vision</CompanyProfileTitle>
              <CompanyProfileBody mobileScreen={mobileScreen}>
                To be a leading diversified financial services group that
                leverages technology and innovation to reach hundreds of
                millions of people globally.
              </CompanyProfileBody>
            </CompanyProfileWrapper>
          </Col>
        </Row>
      </Container>

      <OurVisionCards mobileScreen={mobileScreen} />
    </React.Fragment>
  );
}

const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "100px" : "80px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};
  }
`;
const CompanyProfileWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.mobileScreen ? "310px" : "100%")};
  background: #f2a541;
  border-radius: 10px;
  padding: 40px;
`;

const CompanyProfileTitle = styled.h4`
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
`;
const CompanyProfileBody = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  width: 100%;
`;
