import React from "react";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { Divider } from "antd";
import BlogCardComponent from "./BlogCard";
import NewsCardSection from "../News";
import { Container } from "./BlogCard/styles";

export default function OurInsightsComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");
  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <NewsCardSection />
      </Container>
      <Divider style={{ backgroundColor: "#F3CA40" }} />
      <BlogCardComponent mobileScreen={mobileScreen} />
    </React.Fragment>
  );
}
