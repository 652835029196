import React from 'react';
import BottomHeader from './BottomHeader';
import CompanyProfile from './CompanyProfile';
import OurBusinessCards from './OurBusinessCard';
import Image_1 from '../../Assets/images/ourbusiness-1.png';
import Image_2 from '../../Assets/images/ourbusiness-2.png';
import Image_3 from '../../Assets/images/park.jpg';
import { dataOne, dataTwo, dataThree } from './OurBusinessCard/data';
import { Divider } from 'antd';
import useMediaQuery from '../useMediaQuery/useMediaQuery';

export default function AboutOurBusiness() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");

    const data = {
        titleOne: `Asset Management`,
        descriptionOne: `We help people, businesses and institutions build, preserve and manage wealth so they can pursue their financial goals.
        Global resources and a focus on long-term results are just a few qualities that define our asset management. Institutions and individuals worldwide entrust us to manage enormous investments
        `,
        titleTwo: `Foreign Exchange`,
        descriptionTwo: `
        From simple cross-border trade-related payments to sophisticated risk management solutions, we help you manage all your foreign exchange requirements. 
Our foreign exchange dealers will work together with you to understand your FX risk and develop a tailored strategy for you – helping you stay ahead of the impact of currency volatility on your business. We make provision for retail users to have access to foreign exchange for travel, business or personal use. 
        `,
        titleThree: `Real Estate`,
        descriptionThree: `
        AEG is a niche investor in mix-use real estate, specializing in commercial and residential properties. With an established and experienced team, we have expertise in sourcing buildings with the specific intention of adding or unlocking value either with a view to trading, living or investing long term.
        `,
    }

    return (
        <React.Fragment>
            <BottomHeader mobileScreen={mobileScreen} />
            <CompanyProfile
                mobileScreen={mobileScreen} Image={Image_1}
                title={data.titleOne}
                description={data.descriptionOne}
                bgColor={"#fff"}
                height={'450px'} 
                link={'https://www.convergedynamicscapital.com/'}
                text={' Visit our investment website'}
            />
            <OurBusinessCards mobileScreen={mobileScreen} data={dataOne} height={'550px'} />
            <Divider style={{ backgroundColor: '#F3CA40' }} />
            <CompanyProfile
                mobileScreen={mobileScreen} Image={Image_2}
                title={data.titleTwo}
                description={data.descriptionTwo}
                bgColor={"#fff"}
                height={'450px'}
                link={'https://www.olivebdc.com/'}
                text={' Visit our Foreign Exchange Website'} 
            />
            <OurBusinessCards mobileScreen={mobileScreen} data={dataTwo} height={'400px'} />
            <Divider style={{ backgroundColor: '#F3CA40' }} />
            <CompanyProfile
                mobileScreen={mobileScreen} Image={Image_3}
                title={data.titleThree}
                description={data.descriptionThree}
                bgColor={"#fff"}
                height={'450px'} 
                link={'http://park101.com.ng/'}
                text={' Visit our Real Estate Website'} 
            />
            <OurBusinessCards mobileScreen={mobileScreen} data={dataThree} height={'350px'} />
        </React.Fragment>
    )
}
