
export const BottomData = [
    {
        title: 'Invest',
        path: '/business',
        id: Math.random()
    },
    {
        title: 'Forex',
        path: '/business/forex',
        id: Math.random()
    },
    {
        title: 'Advisory',
        path: '/business/advisory',
        id: Math.random()
    }
];
