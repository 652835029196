import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons/lib/icons";
import { Col, Input, Row } from "antd";
import React from "react";
import styled from "styled-components";
import AEGLogoLight from "../../Assets/images/AEG-Logo-Light.png";
import GetInTouchSection from "../GetInTouch";
import useMediaQuery from "../useMediaQuery/useMediaQuery";

export default function FooterComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  return (
    <React.Fragment>
      <GetInTouchSection mobileScreen={mobileScreen} />
      <div id="here">
        <FooterLayout mobileScreen={mobileScreen}>
          <Row gutter={[0, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
              <FooterLayoutWrapper>
                <FooterLayoutImage alt="" src={AEGLogoLight} />
                <FooterLayoutDescription mobileScreen={mobileScreen}>
                  We are committed to providing financial services that enable
                  growth and economic progress.
                </FooterLayoutDescription>
                <FooterLayoutIcons>
                  <a
                    href="https://www.facebook.com/Asset-and-Equity-Group-10304727855046"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookOutlined />
                  </a>
                  <a
                    href="https://www.instagram.com/assetandequityg/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramOutlined />
                  </a>
                  <a
                    href="https://ng.linkedin.com/company/asset-equity-group?trk=similar-pages"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedinOutlined />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC0F-ouLrzWOVUdvpHj_rTEQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeOutlined />
                  </a>
                </FooterLayoutIcons>
              </FooterLayoutWrapper>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={4} span={4}>
              <FooterLayoutWrapper>
                <FooterLayoutTitle>Company</FooterLayoutTitle>
                <FooterLayoutList>
                  <div>
                    <a href="/about-us">About us</a>
                  </div>
                  <div>
                    <a href="/insights">Insights</a>
                  </div>
                  <div>
                    <a href="/contact-us">Contact us</a>
                  </div>
                  <div>
                    <a href="/business">Busineses</a>
                  </div>
                </FooterLayoutList>
              </FooterLayoutWrapper>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={4} span={4}>
              <FooterLayoutWrapper>
                <FooterLayoutTitle>Support</FooterLayoutTitle>
                <FooterLayoutList>
                  <div>Help center</div>
                  <div> Terms of service</div>
                  <div> Legal</div>
                  <div> Contracts</div>
                </FooterLayoutList>
              </FooterLayoutWrapper>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={4} span={4}>
              <FooterLayoutWrapper>
                <FooterLayoutTitle>Stay up to date</FooterLayoutTitle>
                <FooterLayoutList>
                  <form
                    action="https://assetandequitygroup.us6.list-manage.com/subscribe/post?u=9102f4382a620dbebd65c7398&amp;id=2598b05702"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                  >
                    <Input
                      placeholder={"email"}
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                    />
                    <FooterButton
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    >
                      Submit
                    </FooterButton>
                  </form>
                </FooterLayoutList>
              </FooterLayoutWrapper>
            </Col>
          </Row>
          <FooterLayoutCopy>
            © 2022 Asset and Equity Group. All rights reserved.
          </FooterLayoutCopy>
        </FooterLayout>
      </div>
    </React.Fragment>
  );
}

const FooterLayout = styled.div`
  background-color: #0b0d17;
  height: 100%;
  color: #fff;
  padding: ${(props) =>
    props.mobileScreen ? "50px 100px" : "50px 20px 30px 20px"};

  a {
    color: #fff;
  }
`;

const FooterLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterLayoutTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
`;
const FooterLayoutList = styled.div`
  font-size: 18px;
  line-height: 40px;
  color: #d9dbe1;
  & input {
    padding: 10px;
  }
`;
const FooterLayoutDescription = styled.p`
  width: 100%;
  font-size: 17px;
  line-height: 25px;
  color: #d9dbe1;
  padding: 15px 0px;
  width: ${(props) => (props.mobileScreen ? "400px" : "100%")};
`;
const FooterLayoutImage = styled.img`
  width: 120px;
  height: 100px;
`;
const FooterLayoutIcons = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: row;
  & span {
    padding-left: 10px;
  }
`;
const FooterLayoutCopy = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 17px;
  color: #d9dbe1;
  margin-top: 40px;
`;
const FooterButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f3ca40;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
  color: #fff;
`;
