import Image_1 from "../../../../Assets/images/akin.jpg";
import Image_2 from "../../../../Assets/images/management-2.png";
import Image_3 from "../../../../Assets/images/bernard.jpg";
import Image_4 from "../../../../Assets/images/management-4.jpg";

export const data = [
  {
    id: Math.random(),
    title: "Kayode Adegoye",
    description: `Group Chief Operating Officer`,
    image: Image_2,
  },
  {
    id: Math.random(),
    title: "Adesemoye Akinbola",
    description: `Head, FX and BDC Advisory`,
    image: Image_1,
    bgCover: "cover",
  },
  {
    id: Math.random(),
    title: "Ambrose Onyebuchi Udeaba",
    description: `Head, Investment Management`,
    image: Image_4,
    bgCover: "cover",
  },
  {
    id: Math.random(),
    title: "Bernard Okon",
    description: `Group Financial Controller`,
    image: Image_3,
    bgCover: "cover",
  },
];
