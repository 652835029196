import React from "react";
import { Col, Row } from "antd";
import {
  OurBusinessCardWrapper,
  Container,
  OurBusinessCardBox,
  OurBusinessCardTitle,
  OurBusinessCardDescription,
  OurBusinessCardList,
} from "./styles.js";

export default function OurBusinessCards({ mobileScreen, data, height }) {
  return (
    <React.Fragment>
      <OurBusinessCardWrapper>
        <Container mobileScreen={mobileScreen}>
          <Row gutter={[16, 16]}>
            {data.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  span={8}
                  key={item.id}
                >
                  <OurBusinessCardBox
                    borderColor={item.Color}
                    height={height}
                    mobileScreen={mobileScreen}
                  >
                    <OurBusinessCardTitle size={item.width}>
                      {item.title}
                    </OurBusinessCardTitle>
                    <OurBusinessCardDescription>
                      {item.description}
                    </OurBusinessCardDescription>
                    <OurBusinessCardList borderColor={item.Color}>
                      {item.text1}
                    </OurBusinessCardList>
                    <OurBusinessCardList borderColor={item.Color}>
                      {item.text2}
                    </OurBusinessCardList>
                    <OurBusinessCardList borderColor={item.Color}>
                      {item.text3}
                    </OurBusinessCardList>
                    <OurBusinessCardList borderColor={item.Color}>
                      {item.text4}
                    </OurBusinessCardList>
                  </OurBusinessCardBox>
                </Col>
              );
            })}
          </Row>
        </Container>
      </OurBusinessCardWrapper>
    </React.Fragment>
  );
}
