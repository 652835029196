import React from "react";
import { Col, Row } from "antd";
import { SectionData } from "./data";
import { ArrowRightOutlined } from "@ant-design/icons/lib/icons";
import {
  ServicesCardWrapper,
  Container,
  HeaderTitle,
  ServicesCardBox,
  ServicesCardTitle,
  ServicesCardDescription,
  ServiceButton,
  CustomButton,
} from "./styles.js";

export default function ServicesCards({ mobileScreen }) {
  return (
    <React.Fragment>
      <ServicesCardWrapper>
        <Container mobileScreen={mobileScreen}>
          <HeaderTitle mobileScreen={mobileScreen}>
           <strong>AEG</strong>, giving you <strong>extraordinary</strong> <br /> solutions through...
          </HeaderTitle>
          <Row gutter={[48, 48]}>
            {SectionData.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  span={8}
                  key={item.id}
                >
                  <ServicesCardBox
                    borderColor={item.Color}
                    mobileScreen={mobileScreen}
                  >
                    <ServicesCardTitle
                      size={item.width}
                      mobileScreen={mobileScreen}
                    >
                      {item.title}
                    </ServicesCardTitle>
                    <ServicesCardDescription
                      mobileScreen={mobileScreen}
                      size={item.margin}
                    >
                      {item.description}
                    </ServicesCardDescription>
                    <a href="/about-us">
                      <ServiceButton color={item.Color}>
                        Read More
                        <ArrowRightOutlined />
                      </ServiceButton>
                    </a>
                  </ServicesCardBox>
                </Col>
              );
            })}
          </Row>
          <CustomButton mobileScreen={mobileScreen}>
            <a href="/contact-us">
              Get In Touch <ArrowRightOutlined />
            </a>
          </CustomButton>
        </Container>
      </ServicesCardWrapper>
    </React.Fragment>
  );
}
