import React from "react";
import BottomHeader from "./BottomHeader";
import QuoteSection from "../QuoteSection";
import InvestmentCards from "./InvestmentCard";
import IndividualsHeader from "./Header";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { Card, Col, Divider, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { dataSave } from "./InvestmentServices/data";
import BreadcrumbComponent from "../Breadcrumb";
import { Container, HeaderTitle, ServicesCardDescription, ServicesCardTitle } from "./InvestmentServices/styles";

export default function IndividualSave() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `No matter what you are saving for, 
    we have the savings account that is right for you.`,
    description: `Check your balances, pay bills, send money and more whenever you need to. 
    Bank from anywhere. Take your account from wherever you are, anytime you want.`,
  };

  const Breadcrumb = `Begin your wealth building adventure with us.`;

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <IndividualsHeader mobileScreen={mobileScreen} data={data} size={'850px'}/>
      <InvestmentCards mobileScreen={mobileScreen} />
      <BreadcrumbComponent Breadcrumb={Breadcrumb}/>
      <Container mobileScreen={mobileScreen}>
        <HeaderTitle mobileScreen={mobileScreen}>
        Enjoy our savings plans
        </HeaderTitle>
        <Row gutter={[12, 52]}>
          {dataSave.map((item) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                span={6}
                key={item.id}
              >
                <Card
                  hoverable
                  style={{ width: "100%", height: "100%" }}
                  cover={
                    <img
                      alt=""
                      src={item.image}
                      width={"100%"}
                      height={"300px"}
                      style={{ objectFit: "cover" }}
                    />
                  }
                >
                  <Meta
                    title={<ServicesCardTitle>{item.title}</ServicesCardTitle>}
                    description={
                      <ServicesCardDescription>
                        {item.description}
                      </ServicesCardDescription>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Divider style={{ backgroundColor: "#F3CA40" }} />
      <QuoteSection />
    </React.Fragment>
  );
}
