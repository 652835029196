import React from "react";
import { Col, Row } from "antd";
import {
  InvestmentCardWrapper,
  Container,
  InvestmentCardBox,
  InvestmentCardTitle,
  InvestmentCardDescription,
  InvestmentCardImage,
} from "./styles.js";
import { Investment } from "./data";

export default function InvestmentCards({ mobileScreen }) {
  return (
    <React.Fragment>
      <InvestmentCardWrapper>
        <Container mobileScreen={mobileScreen}>
          <Row gutter={[24, 24]}>
            {Investment.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  span={8}
                  key={item.id}
                >
                  <InvestmentCardBox mobileScreen={mobileScreen}>
                    <InvestmentCardImage src={item.image} alt="" />
                    <InvestmentCardTitle>{item.title}</InvestmentCardTitle>
                    <InvestmentCardDescription>
                      {item.description}
                    </InvestmentCardDescription>
                  </InvestmentCardBox>
                </Col>
              );
            })}
          </Row>
        </Container>
      </InvestmentCardWrapper>
    </React.Fragment>
  );
}
