import React from "react";
import BottomHeader from "./BottomHeader";
import CompanyProfile from "./CompanyProfile";
import OurLeadershipSection from "./OurLeadership";
import OurVisionSection from "./OurVision";
import Image from "../../Assets/images/aboutbg.webp";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { Divider } from "antd";

export default function AboutOurProfile() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `Asset and Equity Group – building resilient portfolios`,
    description: `
        Our approach is to fundamentally question how quality is created, how to enhance value, identify synergies, capitalize on efficiency, and maximize potential. Then we can enjoy bringing creativity and expertise to a deal and execute it in our own way
With over 55 years of combined investment management experience, we have continued to provide cutting edge solutions that help our customers go further and get ahead. Through prosperity, recession, and a pandemic, customers have turned to Asset and Equity Group for the solutions needed to build and manage wealth across multiple generations.

   `,
  };

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <CompanyProfile
        mobileScreen={mobileScreen}
        title={data.title}
        description={data.description}
        Image={Image}
        bgColor={"rgba(196, 196, 196, 0.3)"}
        height={"650px"}
      />
      <OurVisionSection mobileScreen={mobileScreen} />
      <Divider style={{ backgroundColor: "#F3CA40" }} />
      <OurLeadershipSection mobileScreen={mobileScreen} />
    </React.Fragment>
  );
}
