import React from "react";
import 'antd/dist/antd.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {
  HomePage, OurProfileAboutus, OurBusinessAboutus,
  OurTimelineAboutus, InvestPage, ForexPage,SavePage, RealEstatePage,
  OurInsightsPage, CorporatePage, ContactUsPage, BusinessAdvisoryPage,
  BusinessInvestPage, BusinessForexPage, InsightsBlogPage,
} from './Page/index';
import { QueryClient, QueryClientProvider} from "react-query";


const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="*" exact element={<HomePage />} />
          <Route path="/about-us" element={<OurProfileAboutus />} />
          <Route path="/about-us/business" element={<OurBusinessAboutus />} />
          <Route path="/about-us/timeline" element={<OurTimelineAboutus />} />
          <Route path="/individuals" element={<InvestPage />} />
          <Route path="/individuals/forex" element={<ForexPage />} />
          <Route path="/individuals/save" element={<SavePage />} />
          <Route path="/individuals/real-estate" element={<RealEstatePage />} />
          <Route path="/insights" element={<OurInsightsPage />} />
          <Route path="/blog/:slug" element={<InsightsBlogPage />} />
          <Route path="/corporate" element={<CorporatePage />} />
          <Route path="/business" element={<BusinessInvestPage />} />
          <Route path="/business/forex" element={<BusinessForexPage />} />
          <Route path="/business/advisory" element={<BusinessAdvisoryPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
