import styled from "styled-components";

const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "100px 90px" : "70px 20px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "100px 200px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.h4`
  text-align: left;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "32px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  margin-bottom: 10px;
  text-transform: capitalize;
  padding-bottom: ${(props) => (props.mobileScreen ? "30px" : "20px")};
`;
const HeaderTitleSmall = styled.h4`
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: ${(props) => (props.mobileScreen ? "30px" : "25px")};
  line-height: ${(props) => (props.mobileScreen ? "45px" : "40px")};
  color: #0b0d17;
  font-weight: 400;
`;
const ServicesButton = styled.span`
  font-size: 20px;
  line-height: 40px;
  color: #0b0d17;
  text-transform: capitalize;
  border-bottom: 0.5px solid #0b0d17;
  text-align: left;
`;

const ServicesCardTitle = styled.h4`
  font-size: 25px;
  line-height: 40px;
  color: #0b0d17;
`;

const ServicesCardDescription = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #0b0d17;
  text-align: left;
`;
export {
  Container,
  HeaderTitle,
  HeaderTitleSmall,
  ServicesButton,
  ServicesCardTitle,
  ServicesCardDescription,
};
