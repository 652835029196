import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { NavberData } from './Data';
import { IconContext } from 'react-icons';
import { NavMenuItems, NavMapLink, NavbarMobile, NavbarMobileToggle, MobileNavbarMenu } from './styles';
import AEGLOGO from "../../Assets/images/Logo.svg";

const MobileNavbar = () => {
    // Active Navbar Link Menu
    const { pathname } = useLocation();
    const ActivePage = pathname.replace("/", "");

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: '#181717' }}>
                <NavbarMobile>
                    <a href='/'>
                        <img width={'100%'} height={'100%'} src={AEGLOGO} loading="lazy" alt="" />
                    </a>
                    <Link to='#' style={{ fontSize: '2rem', background: 'none' }}>
                        <FaIcons.FaBars onClick={showSidebar} style={{ color: '#fff' }} />
                    </Link>
                </NavbarMobile>
                <MobileNavbarMenu sidebar={sidebar}>
                    <NavMenuItems onClick={showSidebar}>
                        <NavbarMobileToggle>
                            <Link to='#' style={{ fontSize: '2rem', background: 'none' }}>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </NavbarMobileToggle>
                        {NavberData.map((item, index) => {
                            return (
                                <NavMapLink key={index} active={ActivePage} path={item.path.split("/")[1]}>
                                    <Link to={item.path}>
                                        <span style={{ marginLeft: '16px' }}>{item.title}</span>
                                    </Link>
                                </NavMapLink>
                            );
                        })}
                    </NavMenuItems>
                </MobileNavbarMenu>
            </IconContext.Provider>
        </>
    );
}

export default MobileNavbar;
