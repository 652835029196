import { Col, Row } from 'antd';
import React from 'react';
import styled from "styled-components";

export default function BreadcrumbComponent({ mobileScreen, Breadcrumb }) {

    return (
        <React.Fragment>
            <ServicesCardWrapper>
                <Container mobileScreen={mobileScreen}>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} span={16}>
                            <HeaderTitle mobileScreen={mobileScreen}>
                                {Breadcrumb ?
                                    Breadcrumb :
                                    "Never Miss an Insight"
                                }
                            </HeaderTitle>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8} style={{
                            display: 'flex',
                            justifyContent: `${mobileScreen ? "end" : "center"}`
                        }}>
                            <a href='#here'>
                                <CustomButton>
                                    Subscribe Here
                                </CustomButton>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </ServicesCardWrapper>
        </React.Fragment>
    );
}

const ServicesCardWrapper = styled.div`
background-color: #F08A4B;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "60px 100px" : "50px 20px"};
`;
const HeaderTitle = styled.div`
   font-weight: 500;
font-size: ${props => props.mobileScreen ? "30px" : "25px"};
line-height: 45px;
color: #FFFFFF;
display: flex;
justify-content: ${props => props.mobileScreen ? "initial" : "center"};
text-transform: capitalize;
`;

const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    background: #F3CA40;
    border-radius: 30px;
    font-weight: 500;
    font-size: ${props => props.mobileScreen ? "20px" : "18px"};
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 22px;

`;