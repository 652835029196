import { Col, Row } from 'antd';
import React from 'react';
import FileImage from '../../Assets/images/file.png';
import GooglePlay from '../../Assets/images/1.png';
import AppleStore from '../../Assets/images/2.png';
import {
    Container, HeroHeader, HeroHeaderTitle,
    HeroHeaderDescription, HeroHeaderImage
} from './styles';


export default function FileHoreComponent({ mobileScreen }) {

    return (
        <Container mobileScreen={mobileScreen}>
            <Row gutter={[24, 130]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                    <HeroHeader>
                        <HeroHeaderTitle mobileScreen={mobileScreen}>
                            File Ng
                        </HeroHeaderTitle>
                        <HeroHeaderDescription mobileScreen={mobileScreen}>
                            Register your business online with File.
                            Launch your business in Nigeria seamlessly leveraging on our IT solution.
                            Do away with the hassle of trials and errors.
                            You are one click away from registering your company.
                        </HeroHeaderDescription>
                        <Row gutter={[8, 12]}>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} span={6}>
                                <a href="https://play.google.com/store/apps/details?id=com.aeg.file75&hl=en&gl=US" target="_blank" rel="noreferrer">
                                    <img src={GooglePlay} alt="" width={`${mobileScreen ? "100%" : "160px"}`} />
                                </a>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} span={6}>
                                <img src={AppleStore} alt="" width={`${mobileScreen ? "100%" : "160px"}`} />
                            </Col>
                        </Row>
                    </HeroHeader>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                    <HeroHeaderImage mobileScreen={mobileScreen} src={FileImage} alt="" />
                </Col>
            </Row>
        </Container>
    )
}
