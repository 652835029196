import React from "react";
/* import QuoteSection from "../QuoteSection"; */
import InvestmentCards from "./InvestmentCard";
import IndividualsHeader from "./Header";
import LetTalkComponent from "./Breadcrumb";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import CorporateServicesComponent from "./corporateServices";

export default function OurCorporateComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `OUR APPROACH`,
    description: `We seek to harness the power of data to uncover new and previously undiscovered investment opportunities before the broader market prices them in. Our culture of research, combined with specialized industry knowledge and technological advancement, continues to drive evolution in our process as we search for robust, diversified sources of return. `,
  };

  return (
    <React.Fragment>
      <IndividualsHeader mobileScreen={mobileScreen} data={data} />
      <InvestmentCards mobileScreen={mobileScreen} />
      <CorporateServicesComponent mobileScreen={mobileScreen} />
      <LetTalkComponent mobileScreen={mobileScreen} />
      {/* <QuoteSection /> */}
    </React.Fragment>
  );
}
