import { Col, Row } from "antd";
import React from "react";
import ParkImage from "../../Assets/images/parkbg.png";
import {
  Container,
  HeroHeader,
  HeroHeaderTitle,
  HeroHeaderDescription,
  HeroHeaderImage,
  CustomButton,
} from "./styles";

export default function ParkHoreComponent({ mobileScreen }) {
  return (
    <Container mobileScreen={mobileScreen}>
      <Row gutter={[24, 130]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
          <HeroHeader>
            <HeroHeaderTitle mobileScreen={mobileScreen}>
              Park 101
            </HeroHeaderTitle>
            <HeroHeaderDescription mobileScreen={mobileScreen}>
              Own a piece of PARADISE.
              <br />
              Park 101 is a luxury beach front property, covering an area of
              53,000 square meters, located in the emerging industrial hub in
              Ibeju-Lekki. Inspired by luxury holiday destinations, this iconic
              mixed-use community will be home to luxury residential apartments,
              luxury hotel & Resort, commercial outlets, and onsite amenities.
            </HeroHeaderDescription>
            <Row gutter={[0, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} span={12}>
                <a
                  href="http://www.park101.com.ng/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CustomButton>Schedule an appointment</CustomButton>
                </a>
              </Col>
            </Row>
          </HeroHeader>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
          <HeroHeaderImage mobileScreen={mobileScreen} src={ParkImage} alt="" />
        </Col>
      </Row>
    </Container>
  );
}
