import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import CustomHeroComponent from '../../Components/CustomHeroComponent';
import corporateBackImage from '../../Assets/images/corporateBack.png';
import OurCorporateComponent from '../../Components/Corporate';
const { Footer, Content } = Layout;

export default function CorporatePage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <CustomHeroComponent imageBg={`${corporateBackImage}`} title={"Corporate"} />

                <Content>
                    <OurCorporateComponent />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
