
export const NavberData = [
    {
        title: 'Home',
        path: '/',
    },
    {
        title: 'Individuals',
        path: '/individuals',
    },
    {
        title: 'Corporate',
        path: '/corporate',
    },
    {
        title: 'Business',
        path: '/business',
    },
    {
        title: 'About Us',
        path: '/about-us',
    },
    {
        title: 'Insights',
        path: '/insights',
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
    }

];
