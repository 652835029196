import React from "react";
import {
  Container,
  HeaderTitle,
  ServicesCardTitle,
  ServicesCardDescription,
} from "./styles.js";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import "./styles.css";
import { dataCorporate } from "./data.js";

export default function CorporateServicesComponent({ mobileScreen }) {
  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <HeaderTitle mobileScreen={mobileScreen}>
          Our Corporate Services
        </HeaderTitle>
        <Row gutter={[52, 52]}>
          {dataCorporate.map((item) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                span={8}
                key={item.id}
              >
                <Card
                  hoverable
                  style={{ width: "100%", height: "100%" }}
                  cover={
                    <img
                      alt=""
                      src={item.image}
                      width={"100%"}
                      height={"300px"}
                      style={{ objectFit: "cover" }}
                    />
                  }
                >
                  <Meta
                    title={<ServicesCardTitle>{item.title}</ServicesCardTitle>}
                    description={
                      <ServicesCardDescription>
                        {item.description}
                      </ServicesCardDescription>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
}
