import React from "react";
import BottomHeader from "./BottomHeader";
import IndividualsHeader from "./Header";
import QuoteSection from "../QuoteSection";
import ConstructionSection from "./ConstructionSection";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import OurCardSection from "./cardSection/index";

export default function IndividualRealEstate() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `Our Story`,
    description: `At Asset and Equity, we develop and invest in quality real estate assets to deliver attractive long-term returns and unlock maximum value.
        We innovate to realize the potential of space and collaborate with new partners, shifting the emphasis from assets to experiences that meet our clients’ needs.
`,
  };

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <IndividualsHeader mobileScreen={mobileScreen} data={data} />
      {/* <ProjectRatingComponent mobileScreen={mobileScreen} /> */}
      <OurCardSection mobileScreen={mobileScreen} />
      <ConstructionSection mobileScreen={mobileScreen} />
      <QuoteSection mobileScreen={mobileScreen} />
    </React.Fragment>
  );
}
