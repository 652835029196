import React from 'react';
import { Col, Row } from 'antd';
import styled from "styled-components";
import {
    InvestmentCardWrapper, InvestmentCardBox, InvestmentCardTitle,
    InvestmentCardImage
} from './styles.js';
import { Investment } from './data';

export default function IndividualHeaderCards({ mobileScreen }) {
    const Container = styled.div`
    padding: ${props => props.mobileScreen ? "0px 100px 120px 100px" : "0px 20px"};
`;
    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <InvestmentCardWrapper>
                    <Row gutter={[24, 24]}>
                        {Investment.map((item) => {
                            return (
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8} key={item.id}>
                                    <InvestmentCardBox mobileScreen={mobileScreen}>
                                        <InvestmentCardImage src={item.image} alt="" />
                                        <InvestmentCardTitle>
                                            {item.title}
                                        </InvestmentCardTitle>
                                    </InvestmentCardBox>
                                </Col>
                            );
                        })}
                    </Row>
                </InvestmentCardWrapper>
            </Container>
        </React.Fragment>
    );
}

