import styled from "styled-components";

const Container = styled.div`
    padding: ${props => props.mobileScreen ? "120px 100px 120px 100px" : "70px 20px"};
`;
const BottomHeroHeader = styled.div`
    text-align: left;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: ${props => props.mobileScreen ? "40px" : "30px"};
    line-height: 60px;
    color: #0B0D17;
    padding-left: 10px;
`;

const BottomHeaderWrapper = styled.div`
  display: flex;
    flex-direction: row;
    margin-top: 40px;

    & svg {
        width: 50px;
    }
`;
const BottomHeaderText = styled.h4`
display: flex;
    font-size: 18px;
    justify-content: center;
    padding: 5px;
    align-items: end;
`;

const ButtonHeaderWrapper = styled.div`
display: flex;
    justify-content: center;
    margin-top: 90px;
`;
const CustomButton = styled.div`
 & a  {
    display: inline-block;
   background: #F3CA40;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding: 10px 30px;
 }
   & :hover {
        background: #F08A4B;
    }

`;
export {
    Container, BottomHeroHeader, BottomHeaderWrapper,
    BottomHeaderText, CustomButton, ButtonHeaderWrapper
};