import Image_1 from '../../../Assets/icons/Excellence.svg';
import Image_2 from '../../../Assets/icons/Partnership.svg';
import Image_3 from '../../../Assets/icons/Integrity.svg';
import Image_4 from '../../../Assets/icons/Customer.svg';

export const VisionData = [
    {
        id: Math.random(),
        title: `Excellence`,
        description: `We aspire to nothing less than excellence, consistently striving for exceptional performance and innovation aimed at  achieving outstanding results for our clients, our shareholders, and our company.
        `,
        image: Image_1
    },
    {
        id: Math.random(),
        title: `Partnership`,
        description: `We prioritize collaboration and value diversity, creating a culture that fosters inclusiveness, teamwork and an entrepreneurial mindset in the pursuit of professional and personal excellence. 
        `,
        image: Image_2
    },
    {
        id: Math.random(),
        title: `Integrity`,
        description: `Our reputation for integrity is one of our most important assets. We hold ourselves to standards that not only meet those required by the laws and regulations that apply to us, but also to our principles, which are rooted in exceeding our clients’ expectations.`,
        image: Image_3
    },
    {
        id: Math.random(),
        title: `Customer-Centric`,
        description: `We lead with a customer service mindset, understanding that the customer is at the center of what we do, enabling us to anticipate and adapt to the needs of our clients and consumers by delivering thoughtful, innovative solutions.`,
        image: Image_4
    },

];
