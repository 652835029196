import styled from "styled-components";

const ServicesCardWrapper = styled.div`
  background-color: #f0f2f5;
`;
const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "120px 90px" : "70px 20px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 120px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.h4`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "30px")};
  line-height: 40px;
  color: #0b0d17;
  padding-left: 10px;
  padding-bottom: 25px;
`;
const HeaderDescription = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: #0b0d17;
  margin-bottom: 40px;
`;

export { ServicesCardWrapper, Container, HeaderTitle, HeaderDescription };
