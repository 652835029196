
export const SectionData = [
    {
        id: Math.random(),
        title: 'Our People',
        description: `
        We have a diverse pool of professionals with over  55 years of cumulative experience in
         Asset and Wealth Management, Real Estate, Foreign Exchange, Research and Technology, 
         who have continued to provide cutting edge solutions that help our customers go 
         further and get ahead. 
        `,
        Color: '#F3CA40',
        margin: '30px'
    },
    {
        id: Math.random(),
        title: `Our Technology`,
        description: `
        At Asset and Equity Group, you have the best of both worlds. Our world-class digital 
        channels are complemented with our 24/7/365 Client Support Center to give you the
         "high tech for high quality service" experience.
        `,
        Color: '#BCE27F',
        margin: '65px'
    },
    {
        id: Math.random(),
        title: 'Balloon Africa',
        description: `
         A digital investment platform accessed through Balloon Africa, provides clients access to 
         invest in selected investments aligned to their investment objectives.
         Balloon Africa gives you immediate access to local and international investment opportunities. 
        `,
        Color: '#F08A4B',
        margin: '45px'
    },
];
