import React from 'react';
import {
    Container, CompanyProfileWrapper,
    CompanyProfileTitle, CompanyProfileBody
} from './styles';

export default function IndividualsHeader({ mobileScreen, data }) {
    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <CompanyProfileWrapper>
                    <CompanyProfileTitle mobileScreen={mobileScreen}>
                        {data.title}
                    </CompanyProfileTitle>
                    <CompanyProfileBody mobileScreen={mobileScreen}>
                        {data.description}
                    </CompanyProfileBody>
                </CompanyProfileWrapper>
            </Container>
        </React.Fragment>
    )
}

