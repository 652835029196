import { Col, Row, Timeline } from "antd";
import React from "react";
import styled from "styled-components";
import { Carousel } from "antd";
import ImageOne from "../../../Assets/images/construction.png";
import ImageTwo from "../../../Assets/images/corporate-3.png";
import ImageThree from "../../../Assets/images/corporate-6.png";

export default function ConstructionSection({ mobileScreen }) {
  return (
    <React.Fragment>
      <ConstructionWrapper>
        <Container mobileScreen={mobileScreen}>
          <Row gutter={[40, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
              <Carousel autoplay>
                <div>
                  <CompanyProfileImage
                    src={ImageOne}
                    alt=""
                    mobileScreen={mobileScreen}
                  />
                </div>
                <div>
                  <CompanyProfileImage
                    src={ImageTwo}
                    alt=""
                    mobileScreen={mobileScreen}
                  />
                </div>
                <div>
                  <CompanyProfileImage
                    src={ImageThree}
                    alt=""
                    mobileScreen={mobileScreen}
                  />
                </div>
              </Carousel>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
              <CompanyProfileWrapper mobileScreen={mobileScreen}>
                <Timeline>
                  <Timeline.Item color="#4A463E">
                    <CompanyProfileTitle>
                      Jan 2022, Construction begins
                    </CompanyProfileTitle>
                  </Timeline.Item>
                  <Timeline.Item color="#4A463E">
                    <CompanyProfileTitle>
                      Oct 2021, Completion of land deeds
                    </CompanyProfileTitle>
                  </Timeline.Item>
                  <Timeline.Item color="#4A463E">
                    <CompanyProfileTitle>
                      Sep 2021, Gov’s Consent
                    </CompanyProfileTitle>
                  </Timeline.Item>
                  <Timeline.Item color="#4A463E">
                    <CompanyProfileTitle>
                      Feb 2021, Land purchase
                    </CompanyProfileTitle>
                  </Timeline.Item>
                </Timeline>
              </CompanyProfileWrapper>
            </Col>
          </Row>
        </Container>
      </ConstructionWrapper>
    </React.Fragment>
  );
}

const ConstructionWrapper = styled.div`
  background-color: #f0f2f5;
`;
const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "120px 100px" : "10px 30px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "10px 30px")};
  }
`;
const CompanyProfileWrapper = styled.div`
  width: ${(props) => (props.mobileScreen ? "400px" : "100%")};
  height: ${(props) => (props.mobileScreen ? "450px" : "100%")};
  padding: 20px 0px;
`;
const CompanyProfileImage = styled.img`
  width: 100%;
  height: ${(props) => (props.mobileScreen ? "500px" : "100%")};
  object-fit: cover;
`;
const CompanyProfileTitle = styled.h4`
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #0b0d17;
  margin-left: 10px;
`;
