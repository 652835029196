import styled from "styled-components";

const ServicesCardWrapper = styled.div`
  background-color: #f0f2f5;
`;
const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "120px 90px" : "70px 20px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "32px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  padding-left: 10px;
`;

const ServicesCardBox = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-top: 7px solid ${(props) => props.borderColor};
  border-right: 1px solid ${(props) => props.borderColor};
  border-bottom: 1px solid ${(props) => props.borderColor};
  border-left: 1px solid ${(props) => props.borderColor};
  margin-top: 60px;
  padding: ${(props) => (props.mobileScreen ? "90px 40px 10px 40px" : "40px")};

  @media screen and (min-height: 800px) {
    margin-bottom: ${(props) => props.size};
  }
`;

const ServicesCardTitle = styled.h4`
  font-size: ${(props) => (props.mobileScreen ? "40px" : "30px")};
  line-height: 50px;
  color: #0b0d17;
  width: ${(props) => (props.size ? props.size : "200px")};
`;

const ServicesCardDescription = styled.p`
  font-size: ${(props) => (props.mobileScreen ? "20px" : "17px")};
  line-height: 30px;
  color: #0b0d17;

  @media screen and (min-height: 800px) {
    margin-bottom: ${(props) => props.size};
  }
`;

const ServiceButton = styled.div`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};
  border-color: transparent;
  background: transparent;
  box-shadow: none;

  & span {
    padding-left: 10px;
  }
`;

const CustomButton = styled.div`
  text-align: center;
  margin-top: ${(props) => (props.mobileScreen ? "140px" : "100px")};
  & a {
    display: inline-block;
    border: 2px solid #f3ca40;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #f3ca40;
    padding: 10px 30px;
  }
  & :hover {
    border: 2px solid #f08a4b;
    color: #f08a4b;
  }
`;

export {
  ServicesCardWrapper,
  Container,
  HeaderTitle,
  ServicesCardBox,
  ServicesCardTitle,
  ServicesCardDescription,
  ServiceButton,
  CustomButton,
};
