import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import CustomHeroComponent from "../CustomHeroComponent";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import { Skeleton } from "antd";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function OurBlogComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
                title,
                slug,
                mainImage{
                    asset->{
                    _id,
                    url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData)
    return (
      <Container mobileScreen={mobileScreen}>
        <Skeleton avatar paragraph={{ rows: 10 }} />
      </Container>
    );

  return (
    <React.Fragment>
      <CustomHeroComponent
        imageBg={urlFor(postData.mainImage).width(200).url()}
        title={" Our Blog"}
      />
      <Container mobileScreen={mobileScreen}>
        <BlogWrapper mobileScreen={mobileScreen}>
          <BlogTitle mobileScreen={mobileScreen}>{postData.title}</BlogTitle>
          <BlockContent
            blocks={postData.body}
            projectId={"2ygpxd35"}
            dataset={"production"}
          />
        </BlogWrapper>
      </Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) =>
    props.mobileScreen ? "70px 120px 70px 120px" : "50px 20px"};
`;
const BlogWrapper = styled.div`
  width: 100%;
  & div figure img {
    width: 100%;
  }
`;
const BlogTitle = styled.h4`
  font-weight: 600;
  font-size: ${(props) => (props.mobileScreen ? "35px" : "25px")};
  line-height: ${(props) => (props.mobileScreen ? "45px" : "30px")};
  color: #0b0d17;
  width: 100%;
`;
