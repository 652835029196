import React from "react";
import styled from "styled-components";
import useMediaQuery from "../useMediaQuery/useMediaQuery";

export default function CustomHeroComponent({ imageBg, title }) {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  return (
    <React.Fragment>
      <CustomHeroImage imageBg={imageBg} mobileScreen={mobileScreen}>
        <CustomHeroTitle mobileScreen={mobileScreen}>{title}</CustomHeroTitle>
      </CustomHeroImage>
    </React.Fragment>
  );
}

const CustomHeroImage = styled.div`
  background-image: ${(props) =>
    `linear-gradient(to right, rgba(255,0,0,0), rgb(68, 68, 68)),url('${props.imageBg}')`};
  background-position: top;
  background-size: cover;
  height: ${(props) => (props.mobileScreen ? "50vh" : "25vh")};
`;

const CustomHeroTitle = styled.h2`
  display: flex;
  justify-content: center;
  top: 50%;
  left: 0%;
  transform: translate(0px, 100px);
  font-weight: 800;
  font-size: ${(props) => (props.mobileScreen ? "80px" : "40px")};
  line-height: ${(props) => (props.mobileScreen ? "130px" : "60px")};
  color: #ffffff;
  text-align: center;

  @media screen and (min-height: 800px) {
    top: 50%;
    left: 0%;
    transform: translate(0px, 170px);
  }

  @media screen and (max-width: 800px) {
    top: 50%;
    left: 0%;
    transform: translate(0px, 60px);
  }
`;
