import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavberData } from './Data';
import { Link, useLocation } from 'react-router-dom';
import { DesktopItem, Container } from './styles';
import MobileNavbar from './mobile';
import HeaderComponent from './Header';
import useMediaQuery from "../useMediaQuery/useMediaQuery";

export default function DesktopNavbar() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");
    // Active Navbar Link Menu
    const { pathname } = useLocation();
    const ActivePage = pathname.replace("/", "");

    const [current, setCurrent] = useState('Home')

    const handleClick = e => {
        setCurrent({ current: e.key });
    };

    return (
        <React.Fragment>
            {mobileScreen ?
                <>
                    <HeaderComponent />
                    <Container mobileScreen={mobileScreen}>
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
                            {NavberData.map((item) => {
                                return (
                                    <Menu.Item key={item.title}>
                                        <Link to={item.path}>
                                            <DesktopItem active={ActivePage} path={item.path.split("/")[1]}>
                                                {item.title}
                                            </DesktopItem>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    </Container>
                </>
                :
                <MobileNavbar />
            }
        </React.Fragment>
    );
}


