import { ArrowRightOutlined } from '@ant-design/icons/lib/icons';
import { Col, Row } from 'antd';
import React from 'react';
import styled from "styled-components";

export default function LetTalkComponent({ mobileScreen }) {

    return (
        <React.Fragment>
            <ServicesCardWrapper>
                <Container mobileScreen={mobileScreen}>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={20} xl={20} span={20}>
                            <HeaderTitle mobileScreen={mobileScreen}>
                                Tell me more about your requirements
                            </HeaderTitle>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4} span={4} style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CustomButton>
                                <a href='/contact-us'>
                                    Let's Talk <ArrowRightOutlined />
                                </a>
                            </CustomButton>
                        </Col>
                    </Row>
                </Container>
            </ServicesCardWrapper>
        </React.Fragment>
    );
}

const ServicesCardWrapper = styled.div`
background: #FFFFFF;
border: 1px solid #F3CA40;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "60px 100px" : "50px 20px"};
`;
const HeaderTitle = styled.div`
   font-weight: 500;
font-size: 30px;
line-height: 45px;
color: #0B0D17;
display: flex;
justify-content: ${props => props.mobileScreen ? "initial" : "center"};
text-transform: capitalize;
`;

const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    background: #F3CA40;
    border-radius: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    padding: 10px 22px;
   & a{
 color: #FFFFFF;
   }
`;