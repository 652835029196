import React from 'react';
import { Col, Row, Tabs } from 'antd';
import { SectionData } from './data';
import {
    Container, HeaderTitle, CustomButton,
    BusinessImage, BusinessBody, BusinessTitle,
    BusinessList,
} from './styles.js';
import { ArrowRightOutlined } from '@ant-design/icons/lib/icons';

export default function OurBusinesses({ mobileScreen }) {
    const { TabPane } = Tabs;

    return (
        <React.Fragment>
            <Container mobileScreen={mobileScreen}>
                <HeaderTitle mobileScreen={mobileScreen}>
                    Our Businesses
                </HeaderTitle>
                <Tabs defaultActiveKey="1" centered>
                    {SectionData.map((item) => {
                        return (
                            <TabPane tab={item.tab} key={item.key}>
                                <Row gutter={[48, 16]} style={{ marginTop: '20px' }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                                        <BusinessImage src={item.image} alt="" mobileScreen={mobileScreen} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                                        <BusinessBody mobileScreen={mobileScreen} margin={item.margin}>
                                            {item.description}
                                        </BusinessBody>
                                        {
                                            item.services_1 === "" ?
                                                ""
                                                :
                                                <>
                                                    <BusinessTitle>
                                                        Services Offered
                                                    </BusinessTitle>
                                                    <BusinessList>
                                                        <div><span>##</span> {item.services_1}</div>
                                                        <div><span>##</span> {item.services_2}</div>
                                                        <div><span>##</span> {item.services_3}</div>
                                                        {
                                                         item && item.services_4 === '' ?
                                                          ""
                                                          :
                                                          <div><span>##</span> {item.services_4}</div>
                                                        }
                                                    </BusinessList>
                                                </>
                                        }
                                        <CustomButton>
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                Visit Website <ArrowRightOutlined />
                                            </a>
                                        </CustomButton>
                                    </Col>
                                </Row>
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Container>
        </React.Fragment>
    );
}

