
const dataOne = [
    {
        id: Math.random(),
        title: `Individual`,
        description: `Our bespoke asset management solutions are built to serve the individual investment needs of a select client base. We’re here to support your success and build a relationship with you for the long term.
        `,
        Color: '#F3CA40',
        text1: 'Saving',
        text2: 'Funds Investment',
        text3: 'Equity Investment',
        text4: 'Financial Advisory',
    },
    {
        id: Math.random(),
        title: `Business`,
        description: `Whether you are self-employed, a small or medium enterprise, a professional practice or a multi-faceted business, we offer a suite of business solutions tailored to your business requirements.
        `,
        Color: '#BCE27F',
        text1: 'Funds Investment',
        text2: 'Financial Advisory',
        text3: 'Equity Investment',
    },
    {
        id: Math.random(),
        title: `Corporates & Institutions`,
        description: `Asset and Equity Group’s credo is to form lasting partnerships, enabling us to design solutions that align with your unique corporate banking, investment banking and financing needs.`,
        Color: '#F08A4B',
        text1: 'Investment Management',
        text2: 'Advisory',
        text3: 'Risk Management',
        text4: 'Research',
    },

];

const dataTwo = [
    {
        id: Math.random(),
        title: `Cash Services`,
        description: `Do you need cash? Buy and sell foreign exchange directly with us at the click of a button. We are always ready to receive your orders by phone or mail or our social media platforms. We assure you of competitive rates in a safe business environment.
        `,
        Color: '#F3CA40',
    },
    {
        id: Math.random(),
        title: `Payment Services`,
        description: `Do you want to pay for importation? We deliver secure service to drive customer satisfaction. Automatic, fast and yet personalized service. We will connect you with an account manager who knows your business inside out.
        `,
        Color: '#BCE27F',
    },
    {
        id: Math.random(),
        title: `Full Payments processing
        `,
        description: `Leave your foregin exchange needs to us and spend more time building your business. Get the best exchange rates with our solution built for business and save on all transactions.
        `,
        Color: '#F08A4B',
    },
];

const dataThree = [
    {
        id: Math.random(),
        title: `Invest`,
        description: `We provide top-notch FX investment opportunities to our clients within our regulatory standards.
        `,
        Color: '#F3CA40',
    },
    {
        id: Math.random(),
        title: `Forex `,
        description: `We offer a wide range of Forex services to our clients locally and internationally for various forex transactions such as personal and business travel, education, and health.`,
        Color: '#BCE27F',
        width: `211px`,
    },
    {
        id: Math.random(),
        title: `Advisory`,
        description: `We offer professional FX advisory to our clients in need of FX locally and internationally for business.
        `,
        Color: '#F08A4B',
    },
];
export { dataOne, dataTwo, dataThree };