import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import AboutOurProfile from '../../Components/Aboutus/OurProfile';
import CustomHeroComponent from '../../Components/CustomHeroComponent';
import OurProfileImage from '../../Assets/images/ourprofile.png';
const { Footer, Content } = Layout;

export default function OurProfileAboutus() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <CustomHeroComponent imageBg={`${OurProfileImage}`} title={"Our Profile"} />

                <Content>
                    <AboutOurProfile />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
