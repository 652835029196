import styled from "styled-components";

const QuoteSectionWrapper = styled.div`
background-color: #FFFFFF;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "70px 100px" : "70px 20px"};
`;
const QuoteOwnSection = styled.div`
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
`;
const QuoteOwnWrapper = styled.div`
display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
`;
const QuoteOwnName = styled.h4`
font-weight: bold;
font-size: 18px;
line-height: 15px;
color: #18191F;
`;
const QuoteOwnTitle = styled.p`
font-weight: normal;
font-size: 14px;
line-height: 15px;
color: #474A57;
`;
const QuoteWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
const QuoteImage = styled.img`
 width: 33px;
height: 33px;
margin-right: 20px;
`;
const QuoteWorld = styled.h4`
font-size: 18px;
line-height: 27px;
color: #18191F;
width: 100%;
margin-top: 10px;
`;

export {
    QuoteSectionWrapper, Container, QuoteOwnSection,
    QuoteOwnWrapper, QuoteOwnName, QuoteOwnTitle,
    QuoteWrapper, QuoteImage, QuoteWorld,
};
