import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import CustomHeroComponent from '../../Components/CustomHeroComponent';
import contactUsImage from '../../Assets/images/contact-usBack.png';
import ContactUsComponent from '../../Components/ContactUs'
const { Footer, Content } = Layout;

export default function ContactUsPage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <CustomHeroComponent imageBg={`${contactUsImage}`} title={"Contact Us"} />

                <Content>
                    <ContactUsComponent />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
