import React from "react";
import { Col, Row, Tabs } from "antd";
import {
  Container,
  BottomHeroHeader,
  BottomHeaderWrapper,
  BottomHeaderText,
  CustomButton,
  ButtonHeaderWrapper,
} from "./styles";
import { ArrowRightOutlined } from "@ant-design/icons/lib/icons";
import { SectionData } from "./data";
import "./styles.css";

export default function BottomHeader({ mobileScreen }) {
  const { TabPane } = Tabs;

  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <BottomHeroHeader mobileScreen={mobileScreen}>
          What can we do for......
        </BottomHeroHeader>

        <Tabs defaultActiveKey="1" left style={{ marginTop: "1.5rem" }}>
          {SectionData.map((item) => {
            return (
              <TabPane tab={item.tab} key={item.key}>
                <Row gutter={[8, 16]}>
                  <Col xs={8} sm={8} md={8} lg={4} xl={4} span={4}>
                    <a href={item.link1}>
                      <BottomHeaderWrapper>
                        {item.icon1}
                        <BottomHeaderText>{item.text1}</BottomHeaderText>
                      </BottomHeaderWrapper>
                    </a>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={4} xl={4} span={4}>
                    <a href={item.link2}>
                      <BottomHeaderWrapper>
                        {item.icon2}
                        <BottomHeaderText>{item.text2}</BottomHeaderText>
                      </BottomHeaderWrapper>
                    </a>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={4} xl={4} span={4}>
                    <a href={item.link3}>
                      <BottomHeaderWrapper>
                        {item.icon3}
                        <BottomHeaderText>{item.text3}</BottomHeaderText>
                      </BottomHeaderWrapper>
                    </a>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={4} xl={4} span={4}>
                    <a href={item.link4}>
                      <BottomHeaderWrapper>
                        {item.icon4}
                        <BottomHeaderText>{item.text4}</BottomHeaderText>
                      </BottomHeaderWrapper>
                    </a>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>

        <ButtonHeaderWrapper>
          <CustomButton>
            <a href="/contact-us">
              Let’s Talk <ArrowRightOutlined />
            </a>
          </CustomButton>
        </ButtonHeaderWrapper>
      </Container>
    </React.Fragment>
  );
}
