import React from "react";
import BottomHeader from "./BottomHeader";
/* import QuoteSection from "../QuoteSection"; */
import InvestmentCards from "./InvestmentCard";
import BusinessHeader from "./Header";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import InvestmentServicesComponent from "./InvestmentServices/index";
import { Divider } from "antd";
import { dataInvest } from "./InvestmentServices/data";

export default function InvestComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  const data = {
    title: `Invest with us and gain access to world class investments`,
    description: `We know that you and your business are in need of investment. We also know that it isn’t perfect. 
        By understanding the challenges you face, we can give you practical and focused 
        investment solutions. We provide bespoke investment and finance solutions for businesses 
        across a wide range of sectors and we’re dedicated to working with you to achieve your ambitions.`,
  };

  return (
    <React.Fragment>
      <BottomHeader mobileScreen={mobileScreen} />
      <BusinessHeader mobileScreen={mobileScreen} data={data} />
      <InvestmentCards mobileScreen={mobileScreen} bgColor={"#f0f2f5"} />
      <InvestmentServicesComponent
        mobileScreen={mobileScreen}
        data={dataInvest}
      />
      <Divider style={{ backgroundColor: "#F3CA40" }} />
      {/* <QuoteSection /> */}
    </React.Fragment>
  );
}
