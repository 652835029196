import Image_1 from '../../../Assets/images/invest-1.png';
import Image_2 from '../../../Assets/images/invest-2.png';
import Image_3 from '../../../Assets/images/invest-3.png';

export const Investment = [
    {
        id: Math.random(),
        title: `RIGOROUS FUNDAMENTAL RESEARCH`,
        description: `We conduct triangulated fundamental research that is rigorous and we apply dynamic, industry-specific valuation metrics. We stick to the disciplined approach of core values with excellent management teams. We also leverage frequent access to company management teams to gain deep insight.
        `,
        image: Image_1
    },
    {
        id: Math.random(),
        title: `GLOBAL MINDSET, LOCAL INSIGHTS`,
        description: `Our global mindset with our local presence gives us the ability to potentially generate strong consistent returns. We leverage intellectual capabilities across our global markets and investors around the world. Our constant presence allows us to meet regularly with investors, enabling us to gain timely information access and to cover less widely-researched pockets of investment opportunities.
        `,
        image: Image_2
    },
    {
        id: Math.random(),
        title: `ADVANCED TECHNOLOGY`,
        description: `Our Investors have access to an enormous amount of market information  on every investment opportunity that can potentially influence investment decisions. Our team leverages advanced analytics and sophisticated processing technologies to extract values and actionable insights from this flood of information.`,
        image: Image_3
    },

];
