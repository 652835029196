import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";

export default function CompanyProfile({
  mobileScreen,
  Image,
  bgColor,
  title,
  description,
}) {
  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <Row gutter={[12, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileWrapper mobileScreen={mobileScreen} bg={bgColor}>
              <CompanyProfileTitle>{title}</CompanyProfileTitle>
              <CompanyProfileBody mobileScreen={mobileScreen}>
                {description}
              </CompanyProfileBody>
            </CompanyProfileWrapper>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
            <CompanyProfileImage
              src={Image}
              alt=""
              mobileScreen={mobileScreen}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "90px" : "10px 30px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "120px 100px" : "70px 20px")};
  }
`;
const CompanyProfileWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.mobileScreen ? "500px" : "100%")};
  background: ${(props) => props.bg};
  padding: 120px 50px 0px 50px;
`;
const CompanyProfileImage = styled.img`
  width: 100%;
  height: 500px;
`;
const CompanyProfileTitle = styled.h4`
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  color: #0b0d17;
`;
const CompanyProfileBody = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #0b0d17;
  width: 100%;
  text-align: left;
`;
