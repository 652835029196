import { Col, Row } from 'antd';
import React from 'react';
import BallonImage from '../../Assets/images/balloon.png';
import GooglePlay from '../../Assets/images/1.png';
import AppleStore from '../../Assets/images/2.png';
import {
    Container, HeroHeader, HeroHeaderTitle,
    HeroHeaderDescription, HeroHeaderImage,
} from './styles';


export default function BallonHoreComponent({ mobileScreen }) {

    return (
        <Container mobileScreen={mobileScreen}>
            <Row gutter={[24, 130]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                    <HeroHeader>
                        <HeroHeaderTitle mobileScreen={mobileScreen}>
                            Balloon Africa
                        </HeroHeaderTitle>
                        <HeroHeaderDescription mobileScreen={mobileScreen}>
                            Let’s walk with you on your journey to prosperity.
                            Meet your financial goals with Balloon Africa.
                            Wherever you are in your financial journey, we'll work with you
                            to build an investment and wealth strategy to help you reach your goals.
                        </HeroHeaderDescription>
                        <Row gutter={[8, 12]}>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} span={6}>
                                <img src={GooglePlay} alt="" width={`${mobileScreen ? "100%" : "160px"}`} />
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} span={6}>
                                <img src={AppleStore} alt="" width={`${mobileScreen ? "100%" : "160px"}`} />
                            </Col>
                        </Row>
                    </HeroHeader>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
                    <HeroHeaderImage mobileScreen={mobileScreen} src={BallonImage} alt="" />
                </Col>
            </Row>
        </Container>
    )
}
