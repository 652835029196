import styled from "styled-components";

const Container = styled.div`
    padding: ${props => props.mobileScreen ? "120px 100px" : "70px 20px"};
`;
const HeaderTitle = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: ${props => props.mobileScreen ? "40px" : "35px"};
    line-height: 60px;
    color: #0B0D17;
    padding-left: 10px;
    margin-bottom: 20px;
`;


const CustomButton = styled.div`
    text-align: left;
    margin-top: 30px;
 & a  {
    display: inline-block;
    border: 2px solid #F3CA40;
    border-radius: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F3CA40;
    padding: 10px 30px;
 }
   & :hover {
        border: 2px solid #F08A4B;
        color: #F08A4B;
    }

`;

const BusinessImage = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`;
const BusinessBody = styled.p`
font-size: 18px;
line-height: 30px;
    color: #0B0D17;
    width: 100%;
    margin-top: ${props => props.mobileScreen ? "50px" : "0px"};
    padding-top: ${props => props.margin};
`;
const BusinessTitle = styled.h4`
font-weight: 500;
font-size: 22px;
line-height: 30px;
    color: #0B0D17;
`;
const BusinessList = styled.li`
display: inline-block;
font-size: 16px;
line-height: 40px;
    color: #0B0D17;
    

 & span {
     background-color: #ffcc00;
     color: #ffcc00;
     width: 20px;
     height: 20px;
    border-radius: 30px;
    font-size: 6px;
    margin-right: 6px;
    line-height: 40px;
 }
`;

export {
    Container, HeaderTitle, CustomButton,
    BusinessImage, BusinessBody, BusinessTitle,
    BusinessList,
};