import React from 'react';
import { Col, Row } from 'antd';
import {
    OurBusinessCardWrapper, Container, OurBusinessCardBox, OurBusinessCardTitle,
    OurBusinessCardDescription, OurBusinessCardList, HeaderTitle
} from './styles.js';
import { data } from './data';

export default function OurCardSection({ mobileScreen }) {
    return (
        <React.Fragment>
            <OurBusinessCardWrapper>
                <Container mobileScreen={mobileScreen}>
                    <HeaderTitle mobileScreen={mobileScreen}>
                        Buildings for you
                    </HeaderTitle>
                    <Row gutter={[48, 24]}>
                        {data.map((item) => {
                            return (
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8} key={item.id}>
                                    <OurBusinessCardBox borderColor={item.Color}>
                                        <OurBusinessCardTitle size={item.width}>
                                            {item.title}
                                        </OurBusinessCardTitle>
                                        <OurBusinessCardDescription>
                                            {item.description}
                                        </OurBusinessCardDescription>
                                        <OurBusinessCardList borderColor={item.Color}>
                                            {item.saving}
                                        </OurBusinessCardList>
                                        <OurBusinessCardList borderColor={item.Color}>
                                            {item.payment}
                                        </OurBusinessCardList>
                                        <OurBusinessCardList borderColor={item.Color}>
                                            {item.asset}
                                        </OurBusinessCardList>
                                    </OurBusinessCardBox>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </OurBusinessCardWrapper>
        </React.Fragment>
    );
}

