import styled from "styled-components";

const CustomInput = styled.input`
  padding: 15px 20px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
  transition: linear 0.2s;
  margin-bottom: 18px;
  width: 100%;

  :focus {
  outline: none;
  border: 1px solid #F9A826;
  }

::-webkit-input-placeholder {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 17px;
}
`;


const CustomInputButton = styled.button`
  display: flex;
  width: 100%;
  text-decoration: none;
  border-radius: 5px;
  padding: 13px 20px;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  transition: linear 0.2s;
   color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #F9A826;
  margin-top: 10px;
  background: #F9A826;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
    outline: none;
    :focus {
  outline: none;
  border: 1px solid #F9A826;
  }

 :hover {
  box-shadow: 0px 0px 4px 0px #d6d6d6;
  background:#ffcc00c9;
  color: #fff;
}
`;

const CustomTextarea = styled.textarea`
width: 100%;
height: 200px;
background: #FFFFFF;
border: 1px solid  #FFFFFF;
border-radius: 5px;
padding: 10px;
  font-weight: 500;
  font-size: 20px;
 line-height: 30px;
 margin-top: 10px;

  :focus {
  outline: none;
  padding: 10px;
  border: 1px solid  #FFFFFF;
  }

::-webkit-input-placeholder {
  color: #4d4d4d;
  padding: 10px;
}
`;

const CustomFormGroup = styled.div`
 position: relative;
  display: flex;
  flex-direction: column;
`;

export {
  CustomFormGroup, CustomInput,
  CustomInputButton, CustomTextarea,
};