import React from 'react';
import Image from '../../Assets/images/bill-one-hundred-dollars-holed-paper.png';
import BottomHeader from './BottomHeader';
import BusinessHeader from './Header';
import MMMComponent from './MMM';
import useMediaQuery from '../useMediaQuery/useMediaQuery';
import CompanyProfile from './CompanyProfile';
import NewsCardSection from '../News';
import { Divider } from 'antd';

export default function AdvisoryComponent() {
    // Mobile screen 
    const mobileScreen = useMediaQuery("(min-width: 800px)");

    const header = {
        title: `Advisory plans exclusive to your business`,
        description: `We assist you in managing all of your foreign exchange requirements, 
        from basic cross-border trade-related payments to complicated hedging solutions. 
        Our foreign exchange dealers will work with you to assess your foreign exchange 
        risk and build a customized strategy for you, 
        allowing you to stay ahead of the impact of currency volatility on your business.`,
    }

    const data = {
        title: `You see financial challenges, we see financial possibilities`,
        description: `AEG is a niche investor in mix-use real estate, specializing in commercial 
        and residential properties. With an established and experienced team, 
        we have expertise in sourcing buildings with the specific intention of adding or
         unlocking value either with a view to trading, living or investing long term.`,
    }

    return (
        <React.Fragment>
            <BottomHeader mobileScreen={mobileScreen} />
            <BusinessHeader mobileScreen={mobileScreen} data={header} />
            <MMMComponent mobileScreen={mobileScreen} />
            <NewsCardSection mobileScreen={mobileScreen} />
            <Divider style={{ backgroundColor: '#F3CA40' }} />
            <CompanyProfile
                mobileScreen={mobileScreen}
                Image={Image}
                title={data.title}
                description={data.description}
                bgColor={"#fff"}
            />
        </React.Fragment>
    )
}
