import styled from "styled-components";

const MMMWrapper = styled.div`
background-color: #FFFFFF;
border-top: 1px solid #0B0D17;
border-bottom: 1px solid #0B0D17;
`;
const Container = styled.div`
    padding: ${props => props.mobileScreen ? "60px 100px 60px 100px" : "50px 30px"};
`;
const HeaderTitle = styled.div`
font-weight: 500;
font-size: 30px;
line-height: 45px;
color: #0B0D17;
text-align: left;
margin-bottom: 40px
`;

const CustomButton = styled.div`
    text-align: center;
    display: inline-block;
    background: #577590;
    border-radius: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 22px;

`;

export { MMMWrapper, Container, HeaderTitle, CustomButton };