import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../../Components/Navbar/desktop';
import FooterComponent from '../../Components/Footer';
import OurBlogComponent from '../../Components/Blog';
const { Footer, Content } = Layout;

export default function InsightsBlogPage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />

                <Content>
                    <OurBlogComponent />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
