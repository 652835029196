import React from 'react';
import { Layout } from 'antd';
import DesktopNavbar from '../Components/Navbar/desktop';
import HeroComponent from '../Components/Header/index';
import HomeContentSeactions from '../Components/Home';
import FooterComponent from '../Components/Footer';
const { Footer, Content } = Layout;

export default function HomePage() {
    return (
        <React.Fragment>
            <Layout>
                <DesktopNavbar />
                <HeroComponent />

                <Content>
                    <HomeContentSeactions />
                </Content>

                <Footer>
                    <FooterComponent />
                </Footer>
            </Layout>
        </React.Fragment>
    )
}
