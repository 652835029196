import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import useMediaQuery from "../useMediaQuery/useMediaQuery";
import DetailsComponent from "./details";
import FormsComponent from "./form";

export default function ContactUsComponent() {
  // Mobile screen
  const mobileScreen = useMediaQuery("(min-width: 800px)");

  return (
    <React.Fragment>
      <Container mobileScreen={mobileScreen}>
        <Row gutter={[0, 12]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={11} span={11}>
            <DetailsComponent />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={13} span={13}>
            <FormsComponent />
          </Col>
        </Row>
      </Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15858.146337880991!2d3.3897216!3d6.4534821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc699c971c43d72e5!2sAsset%20And%20Equity%20Group!5e0!3m2!1sen!2sng!4v1643235947899!5m2!1sen!2sng"
        width="100%"
        height="350"
        frameBorder="0"
        style={{ border: 0 }}
        aria-hidden="false"
        tabIndex="0"
        allowFullScreen
        loading="lazy"
        title="jsx-a11y/iframe-has-title"
      />
    </React.Fragment>
  );
}

const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "120px 200px" : "60px 20px")};
`;
