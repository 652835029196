import styled from "styled-components";

const OurBusinessCardWrapper = styled.div`
  background-color: #ffffff;
`;
const Container = styled.div`
  padding: ${(props) => (props.mobileScreen ? "70px 100px" : "70px 20px")};

  @media screen and (min-height: 800px) {
    padding: ${(props) => (props.mobileScreen ? "70px 100px" : "70px 20px")};
  }
`;
const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: ${(props) => (props.mobileScreen ? "40px" : "32px")};
  line-height: ${(props) => (props.mobileScreen ? "60px" : "40px")};
  color: #0b0d17;
  padding-left: 10px;
`;

const OurBusinessCardBox = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-top: 7px solid ${(props) => props.borderColor};
  border-right: 1px solid ${(props) => props.borderColor};
  border-bottom: 1px solid ${(props) => props.borderColor};
  border-left: 1px solid ${(props) => props.borderColor};
  margin-top: 30px;
  padding: 60px 30px 10px 30px;
`;

const OurBusinessCardTitle = styled.h4`
  font-size: 40px;
  line-height: 50px;
  color: #0b0d17;
  width: 100%;
`;

const OurBusinessCardDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #0b0d17;
  text-align: left;
`;
const OurBusinessCardList = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0b0d17;
  text-decoration: underline;
  text-decoration-color: ${(props) => props.borderColor};
`;
export {
  OurBusinessCardWrapper,
  Container,
  HeaderTitle,
  OurBusinessCardBox,
  OurBusinessCardTitle,
  OurBusinessCardDescription,
  OurBusinessCardList,
};
