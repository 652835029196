import Image_1 from "../../../Assets/images/corporate-1.png";
import Image_2 from "../../../Assets/images/corporate-2.png";
import Image_3 from "../../../Assets/images/corporate-3.png";
import Image_4 from "../../../Assets/images/corporate-4.png";
import Image_5 from "../../../Assets/images/corporate-5.png";
import Image_6 from "../../../Assets/images/corporate-6.png";

export const dataCorporate = [
  {
    id: Math.random(),
    title: `Portfolio Management`,
    description: `
    We combine traditional and alternative investments strategies into a segregated portfolio to ensure our clients meet their investment objectives with consideration to their risk profile, time horizon and liquidity needs.
        `,
    image: Image_1,
  },
  {
    id: Math.random(),
    title: `Property Equity  & Trading`,
    description: `We build distinctive relationships through equity and development transactions with partners that have access to attractive property opportunities. We source, build and pursue opportunities to unlock value.
        `,
    image: Image_2,
  },
  {
    id: Math.random(),
    title: `Foreign Exchange`,
    description: `
    We provide cutting edge foreign exchange solutions that makes cross border payments simple.
We are authorized by the Central bank of Nigeria as a BDC for the sale of Business Travel Allowance (BTA) and Personal Travel Allowance (PTA) to travellers that qualify.
    `,
    image: Image_3,
  },
  {
    id: Math.random(),
    title: `Business Advisory`,
    description: `
    Our business advisory is a specialized financial services business comprising thoroughbred professionals focused on providing expert advisory services to businesses, government agencies, family offices and other client groups
    `,
    image: Image_4,
  },
  {
    id: Math.random(),
    title: `Regulatory Advocacy`,
    description: `
    We provide consultancy services to business that requires regulatory approvals or operating licences across PENCOM, NAICOM, SEC and CBN.
    `,
    image: Image_5,
  },
  {
    id: Math.random(),
    title: `Company Incorporation`,
    description: `
    We provide business start-up kit for new companies to help them navigate their early stages after set up, such as business valuation, business name registration and incorporation.
    `,
    image: Image_6,
  },
];
